import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Grid, Pagination, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LogoutPopup from "../../component/Popup/Logoutpopup";
import { IoEyeOutline } from "react-icons/io5";
import { BiEditAlt } from "react-icons/bi";
import { BiBlock } from "react-icons/bi";
import { getAPIHandler } from "../../config/service";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import DataNotFound from "../DataNotFound";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(0, 176, 80)",
    color: theme.palette.common.white,
    fontSize: "1rem",
    fontWeight: 400,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(even)": {
    backgroundColor: "rgba(0, 176, 80, 0.05)",
  },
  "&:last-child td, &:last-child th": {},
  "& td ": {},
}));
export default function CustomizedTables(props) {
  const {
    searchQuery,
    fromDate,
    toDate,
    category,
    constructionStatus,
    setDownloadCSVFunction,
    memberId,
    showEditAndBlockIcons,
  } = props;
  const [isLogoutPopupOpen, setIsLogoutPopupOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState(0);
  const [selectedPropertyId, setSelectedPropertyId] = React.useState(null);
  const [selectedPropertyStatus, setSelectedPropertyStatus] =
    React.useState(null);
  const [filter, setFilter] = React.useState({
    page: 1,
    limit: 10,
  });
  const navigate = useNavigate();

  const handleLogoutClick = (propertyId, status) => {
    setIsLogoutPopupOpen(true);
    setSelectedPropertyId(propertyId);
    setSelectedPropertyStatus(status);
  };

  const handleLogoutConfirm = () => {
    setIsLogoutPopupOpen(false);
    setSelectedPropertyId(null);
  };

  const handleChangePage = (event, value) => {
    setFilter({ ...filter, page: value });
  };

  const handleEditClick = (propertyId) => {
    navigate(`/propertymanagement/editproperty/${propertyId}`);
  };

  const handleViewClick = (propertyId) => {
    navigate(`/propertymanagement/viewproperty/${propertyId}`);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    if (date.toDateString() === today.toDateString()) {
      return "Today";
    } else if (date.toDateString() === tomorrow.toDateString()) {
      return "Tomorrow";
    } else {
      return date.toLocaleDateString("en-US");
    }
  };

  React.useEffect(() => {
    getData();
  }, [
    filter,
    searchQuery,
    fromDate,
    toDate,
    category,
    constructionStatus,
    memberId,
    showEditAndBlockIcons,
  ]);

  React.useEffect(() => {
    setDownloadCSVFunction(() => handleDownloadCSV);
  }, [setDownloadCSVFunction]);

  const getData = async () => {
    try {
      const formattedFromDate = fromDate
        ? format(new Date(fromDate), "yyyy-MM-dd")
        : undefined;
      const formattedToDate = toDate
        ? format(new Date(toDate), "yyyy-MM-dd")
        : undefined;
      const paramsData = {
        ...filter,
        name: searchQuery || undefined,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        category: category || undefined,
        constructionStatus: constructionStatus || undefined,
        memberId: memberId || undefined,
      };

      const response = await getAPIHandler({
        endPoint: "getAllProperty",
        paramsData,
      });

      console.log("Response:", response.data);
      setData(response.data.result.property);
      setTotalPages(response.data.result.totalPages || 0);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDownloadCSV = () => {
    const headers = [
      "S.No.",
      "Property Name",
      "Listed Date",
      "Type",
      "Construction Status",
      "Added By",
      "Updated Date & Time",
      "Status",
    ];

    const csvContent = [
      headers.join(","),
      ...data.map((row, index) =>
        [
          index + 1 + (filter.page - 1) * filter.limit,
          row.propertyName,
          new Date(row.createdAt).toLocaleString("en-IN", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            timeZone: "Asia/Kolkata",
          }),
          row.category,
          row.constructionStatus,
          row.memberType,
          formatDate(row.updatedAt),
          row.status,
        ].join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const timestamp = format(new Date(), "yyyy-MM-dd_HH:mm");
    saveAs(blob, `property_list_${timestamp}.csv`);
  };

  const renderCellWithTooltip = (content, limit) => {
    if (content.length > limit) {
      return (
        <Tooltip title={content}>
          <span>{`${content.substring(0, limit)}...`}</span>
        </Tooltip>
      );
    }
    return content;
  };

  const createRowsFromPropertyManagementData = (data) => {
    return data.map((management, index) => {
      return {
        index: index + 1,
        _id: management._id,
        propertyName: management.propertyName,
        createdAt: new Date(management.createdAt).toLocaleString("en-IN", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "Asia/Kolkata",
        }),
        category: management.category,
        updatedAt: formatDate(management.updatedAt),
        constructionStatus: management.constructionStatus,
        status: management.status,
        memberType: management.memberType,
      };
    });
  };


  const rows = data.length > 0 ? createRowsFromPropertyManagementData(data) : [];

  return (
    <div>
    {data.length < 1 ? (
      <DataNotFound />
    ) : (
      <TableContainer
        component={Paper}
        sx={{
          margin: "auto",
          boxShadow: "0 0 0px rgba(0, 0, 0, 0.1)",
          maxHeight: 500,
          scrollbarWidth: "none",
          borderRadius: "15px",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">S.No.</StyledTableCell>
              <StyledTableCell align="left">Property Name</StyledTableCell>
              <StyledTableCell align="left">Listed Date</StyledTableCell>
              <StyledTableCell align="left">Type</StyledTableCell>
              <StyledTableCell align="left">Possession Status</StyledTableCell>
              <StyledTableCell align="left">
                Construction Status
              </StyledTableCell>

              <StyledTableCell align="left">Status</StyledTableCell>
              <StyledTableCell align="left">Added By</StyledTableCell>
              <StyledTableCell align="left">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell align="left">{row.index}</StyledTableCell>
                <StyledTableCell align="left">
                  {renderCellWithTooltip(row.propertyName, 20)}
                </StyledTableCell>
                <StyledTableCell align="left">{row.createdAt}</StyledTableCell>
                <StyledTableCell align="left">{row.category}</StyledTableCell>
                <StyledTableCell align="left">{row.updatedAt}</StyledTableCell>
                <StyledTableCell align="left">
                  {row.constructionStatus}
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  style={{ color: row.status === "ACTIVE" ? "green" : "red" }}
                >
                  {row.status}
                </StyledTableCell>
                <StyledTableCell align="left">{row.memberType}</StyledTableCell>
                <StyledTableCell>
                  <Grid
                    style={{
                      display: "flex",
                      gap: "0.7rem",
                      alignItems: "center",
                    }}
                  >
                    <IoEyeOutline
                      onClick={() => handleViewClick(row._id)}
                      style={{ fontSize: "24px" }}
                    />
                    {showEditAndBlockIcons && (
                      <Grid
                        style={{
                          display: "flex",
                          gap: "0.7rem",
                          alignItems: "center",
                        }}
                      >
                        <BiEditAlt
                          onClick={() => handleEditClick(row._id)}
                          style={{ fontSize: "24px" }}
                        />
                        <BiBlock
                          onClick={() => {
                            handleLogoutClick(row._id, row.status);
                          }}
                          style={{
                            color: row.status === "ACTIVE" ? "green" : "red",
                            fontSize: "24px",
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>)}
      {isLogoutPopupOpen && (
        <LogoutPopup
          isOpen={isLogoutPopupOpen}
          onClose={handleLogoutConfirm}
          name={
            selectedPropertyStatus === "ACTIVE"
              ? "Block Property?"
              : "Unblock Property?"
          }
          description={
            selectedPropertyStatus === "ACTIVE"
              ? "Are you sure you want to block this property?"
              : "Are you sure you want to unblock this property?"
          }
          cancelbtn="No"
          okbtn={selectedPropertyStatus === "ACTIVE" ? "Block" : "Unblock"}
          route="property"
          propertyId={selectedPropertyId}
          data={data}
          setData={setData}
          type="property"
        />
      )}

      {totalPages > 1 && (
        <Grid container justifyContent="center" mt={3}>
          <Pagination
            color="success"
            count={totalPages}
            page={filter.page}
            onChange={handleChangePage}
          />
        </Grid>
      )}

      <button
        id="property-tablesdata-download"
        style={{ display: "none" }}
        onClick={handleDownloadCSV}
      >
        Download CSV
      </button>
    </div>
  );
}
