import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Typography, Grid, Paper, Divider } from "@mui/material";
import Box from "@mui/material/Box";
import MainLayout from "../../layout/MainLayout";
import CustomizedTable from "../../component/Table/RequestedLeadMangemetData";
import CustomizedTables from "../../component/Table/LeadsManagementData";
import LeadFilter from "../../component/Filters/Filter";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Box sx={{ padding: "0px 10px" }}>
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


export default function MainLeadManagement() {
  const [value, setValue] = React.useState(0);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);


  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <MainLayout>
      <Grid container alignItems="center" padding={2}>
        <Typography variant="h4" sx={{ fontWeight: 700 }}>
          Leads Management
        </Typography>
      </Grid>

      <Grid sx={{ overflowY: "auto" }} paddingX={2}>
        <Paper elevation={0}>
          <Grid container spacing={2} padding={1}>
            <Grid item xs={2}>
            </Grid>

            <Grid item xs={8}>
              <Box Box display="flex" justifyContent="center">
                <Tabs value={value} onChange={handleChange} centered>
                  {["Requested Lead", "Generated  Lead"].map(
                    (label, index) => (
                      <Tab
                        key={index}
                        label={
                          <Typography
                            sx={{
                              textTransform: "none",
                              color:
                                value === index
                                  ? "rgba(0, 176, 80, 1)"
                                  : "#333",
                            }}
                          >
                            {label}
                          </Typography>
                        }
                      />
                    )
                  )}
                </Tabs>
              </Box>
            </Grid>
          </Grid>

          <Divider sx={{ margin: 1 }} />

          <CustomTabPanel value={value} index={0}>
          <Grid>
          <CustomizedTable
            searchQuery={searchQuery}
            fromDate={fromDate}
            toDate={toDate}
          />
        </Grid>
              
           
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            <Grid>
              <Box sx={{ marginBottom: "15px" }}>
                <LeadFilter
                  searchPlaceholder={"Search by property name"}
                  onSearch={handleSearch}
                  onFromDateChange={handleFromDateChange}
                  onToDateChange={handleToDateChange}
                />
              </Box>
              <CustomizedTables
                searchQuery={searchQuery}
                fromDate={fromDate}
                toDate={toDate}
              />
            </Grid>
          </CustomTabPanel>

        
        </Paper>
      </Grid>
    </MainLayout>
  );
}
