import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import MainLayout from "../../../layout/MainLayout";
import { Paper, Grid, Box, Typography } from "@mui/material";
import arrowBack from "../../../assets/arrowBack.svg";
import Input from "@mui/joy/Input";
import Textarea from "@mui/joy/Textarea";
import "./UpdateVP.css";
import Select from "react-select";
import ButtonComponent from "../../../component/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAPIHandler, putAPIHandler } from "../../../config/service";
import { vpSchema } from "../../../schemas";
import { State, City } from "country-state-city";
import { toast } from "react-hot-toast";

const EditVP = () => {
  const [data, setData] = React.useState(null);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    fetchStateOptions();
    getData();
  }, []);

  const fetchStateOptions = () => {
    const states = State.getStatesOfCountry("IN").map((state) => ({
      value: state.isoCode,
      label: state.name,
    }));
    setStateOptions(states);
  };

  const handleStateChange = (selectedState, formik) => {
    if (!selectedState) {
      setCityOptions([]);
      formik.setFieldValue("city", "");
      return;
    }

    const cities = City.getCitiesOfState("IN", selectedState.value).map(
      (city) => ({
        value: city.name,
        label: city.name,
      })
    );
    setCityOptions(cities);
    formik.setFieldValue("state", selectedState.label);
    formik.setFieldValue("city", "");
  };

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await putAPIHandler({
        endPoint: "updateProfile",
        dataToSend: {
          userId: id,
          userType: "VP",
          userRoleType: values.roleType,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          mobileNumber: values.phoneNumber,
          state: values.state,
          city: values.city,
          address: values.address,
        },
      });

      console.log(response.data.result);
      setSubmitting(false);
      toast.success("Profile Updated Successfully");
      navigate("/vpmanagement");
    } catch (error) {
      console.error("Error:", error);
      setSubmitting(false);
    }
  };

  const getData = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "viewProfile",
        paramsData: {
          userId: id,
        },
      });

      console.log("Response:", response.data);
      setData(response.data.result);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <MainLayout>
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" padding="1rem">
          <Typography style={{ fontWeight: 700, fontSize: "30px" }}>
            VP Management
          </Typography>
        </Box>
        <Box sx={{ marginLeft: "15px", marginBottom: "15px" }}>
          <Paper
            elevation={0}
            style={{
              padding: "15px",
              overflowY: "auto",
              scrollbarWidth: "none",
            }}
          >
            <Box display="flex" alignItems="center" mb={2}>
              <Link to={"/vpmanagement"}>
                <img
                  src={arrowBack}
                  alt="arroWback"
                  style={{
                    width: "2.813rem",
                    height: "2.813rem",
                    cursor: "pointer",
                  }}
                />
              </Link>
              <Typography variant="h5" sx={{ marginLeft: "1rem" , fontWeight: 800 }}>
              Update Vice President
            </Typography>
            </Box>

            <Box display="flex" justifyContent="center" margin="2rem">
              <Typography
                variant="h6"
                sx={{ marginLeft: "1rem", fontWeight: 700 }}
              >
                Member Details
              </Typography>
            </Box>

            <Formik
              initialValues={{
                roleType: data ? data.userRoleType : "",
                firstName: data ? data.firstName : "",
                lastName: data ? data.lastName : "",
                email: data ? data.email : "",
                phoneNumber: data ? data.mobileNumber : "",
                state: data ? data.state : "",
                city: data ? data.city : "",
                address: data ? data.address : "",
              }}
              enableReinitialize={true}
              validationSchema={vpSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, setFieldValue, handleBlur, values }) => (
                <Form>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                  >
                    <Box mb={2} width="100%" maxWidth="500px">
                      <div className="role-type">
                        <label>
                          <strong>Role Type</strong>
                        </label>

                        <label>
                          <Field type="radio" name="roleType" value="SALES" />
                          Sales
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="roleType"
                            value="PRE_SALES"
                          />
                          Presales
                        </label>
                      </div>
                      <ErrorMessage
                        name="roleType"
                        component="div"
                        className="error"
                      />
                    </Box>
                    <Box mb={2} width="100%" maxWidth="500px">
                      <Field
                        as={Input}
                        type="text"
                        name="firstName"
                        placeholder="Enter first name"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="error"
                      />
                    </Box>
                    <Box mb={2} width="100%" maxWidth="500px">
                      <Field
                        as={Input}
                        type="text"
                        name="lastName"
                        placeholder="Enter last name"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="error"
                      />
                    </Box>
                    <Box mb={2} width="100%" maxWidth="500px">
                      <Field
                        as={Input}
                        type="email"
                        name="email"
                        placeholder="Enter email address"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error"
                      />
                    </Box>
                    <Box mb={2} width="100%" maxWidth="500px">
                      <Field
                        as={Input}
                        type="tel"
                        name="phoneNumber"
                        placeholder="Enter phone number"
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="error"
                      />
                    </Box>
                    <Box mb={2} width="100%" maxWidth="500px">
                      <div className="s-select">
                        <Select
                          id="state"
                          name="state"
                          options={stateOptions}
                          value={stateOptions.find(
                            (option) => option.label === values.state
                          )}
                          onChange={(selectedOption) => {
                            setFieldValue(
                              "state",
                              selectedOption ? selectedOption.label : ""
                            );
                            handleStateChange(selectedOption, {
                              setFieldValue,
                            });
                          }}
                          onBlur={handleBlur}
                          isClearable={true}
                          placeholder="Select State"
                        />
                        <ErrorMessage
                          name="state"
                          component="div"
                          className="error"
                        />
                      </div>
                    </Box>
                    <Box mb={2} width="100%" maxWidth="500px">
                      <div className="s-select">
                        <Select
                          id="city"
                          name="city"
                          options={cityOptions}
                          value={
                            cityOptions.find(
                              (option) => option.label === values.city
                            ) || ""
                          }
                          onChange={(selectedOption) =>
                            setFieldValue(
                              "city",
                              selectedOption ? selectedOption.label : ""
                            )
                          }
                          onBlur={handleBlur}
                          isClearable={true}
                          placeholder="Select City"
                        />
                        <ErrorMessage
                          name="state"
                          component="div"
                          className="error"
                        />
                      </div>
                    </Box>
                    <Box mb={2} width="100%" maxWidth="500px">
                      <Field
                        as={Textarea}
                        name="address"
                        placeholder="Address"
                      />
                      <ErrorMessage
                        name="address"
                        component="div"
                        className="error"
                      />
                    </Box>
                    <Box
                      mb={2}
                      width="100%"
                      display="flex"
                      justifyContent="center"
                    >
                      <ButtonComponent
                        type="submit"
                        name={"Update Member"}
                        disabled={isSubmitting}
                        height="43px"
                        fullWidth
                      />
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Paper>
        </Box>

        
      </Box>
    </MainLayout>
  );
};

export default EditVP;
