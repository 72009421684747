import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Grid, Pagination, Tooltip } from "@mui/material";
import { getAPIHandler } from "../../config/service";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(0, 176, 80)",
    color: theme.palette.common.white,
    fontSize: "0.93rem",
    fontWeight: 400,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(even)": {
    backgroundColor: "rgba(0, 176, 80, 0.05)",
  },
  "& td": {},
}));

export default function CustomizedTables() {
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = React.useState(1);
  const rowsPerPage = 10;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "generateTableReport",
      });

      console.log(response, "response")
      const reportData = response.data.result.reportData;
      const totalPages = response.data.result.totalPages;

      setData(reportData);
      setTotalPages(totalPages);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const renderCellWithTooltip = (content, limit) => {
    if (content.length > limit) {
      return (
        <Tooltip title={content}>
          <span>{`${content.substring(0, limit)}...`}</span>
        </Tooltip>
      );
    }
    return content;
  };

  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{
          margin: "auto",
          boxShadow: "0 0 0px rgba(0, 0, 0, 0.1)",
          maxHeight: 650,
          scrollbarWidth: "none",
          borderRadius: "15px",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Property Name</StyledTableCell>
              <StyledTableCell align="left">Source</StyledTableCell>
              <StyledTableCell align="left">VP Name</StyledTableCell>
              <StyledTableCell align="left">Manager Name</StyledTableCell>
              <StyledTableCell align="left">Executive Name</StyledTableCell>
              <StyledTableCell align="left">Generated Leads</StyledTableCell>
              <StyledTableCell align="left">Booked Visit</StyledTableCell>
              <StyledTableCell align="left">Confirmed Bookings</StyledTableCell>
              <StyledTableCell align="left">Date</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(startIndex, endIndex).map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell align="left">{renderCellWithTooltip(row.propertyName, 15)}</StyledTableCell>
                <StyledTableCell align="left">{row.source}</StyledTableCell>
                <StyledTableCell align="left">{row.vp}</StyledTableCell>
                <StyledTableCell align="left">{row.manager}</StyledTableCell>
                <StyledTableCell align="left">{row.executive}</StyledTableCell>
                <StyledTableCell align="left">{row.generatedLeads}</StyledTableCell>
                <StyledTableCell align="left">{row.bookedVisits}</StyledTableCell>
                <StyledTableCell align="left">{row.confirmedBookings}</StyledTableCell>
                <StyledTableCell align="left">
                  {new Date(row.date).toLocaleString("en-IN", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    timeZone: "Asia/Kolkata",
                  })}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination */}
      <Grid container justifyContent="center" mt={3}>
        <Pagination
          color="success"
          count={totalPages}
          page={page}
          onChange={handleChangePage}
        />
      </Grid>
    </div>
  );
}
