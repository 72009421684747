import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import MainLayout from "../../../layout/MainLayout";
import { Paper, Typography, Box } from "@mui/material";
import arrowBack from "../../../assets/arrowBack.svg";
import Input from "@mui/joy/Input";
import Select from "react-select";
import Textarea from "@mui/joy/Textarea";
import ButtonComponent from "../../../component/Button";
import { Link, useNavigate } from "react-router-dom";
import { postAPIHandler } from "../../../config/service";
import { vpSchema } from "../../../schemas";
import { State, City } from "country-state-city";
import { toast } from "react-hot-toast";

const AddVP = () => {
  const navigate = useNavigate();
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  useEffect(() => {
    fetchStateOptions();
  }, []);

  const fetchStateOptions = () => {
    const states = State.getStatesOfCountry("IN").map((state) => ({
      value: state.isoCode,
      label: state.name,
    }));
    setStateOptions(states);
  };

  const handleStateChange = (selectedState, setFieldValue) => {
    if (!selectedState) {
      setCityOptions([]);
      setFieldValue("city", "");
      return;
    }

    const cities = City.getCitiesOfState("IN", selectedState.value).map(
      (city) => ({
        value: city.name,
        label: city.name,
      })
    );
    setCityOptions(cities);
    setFieldValue("city", "");
  };

  return (
    <MainLayout>
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" padding="1rem">
          <Typography style={{ fontWeight: 700, fontSize: "30px" }}>
            VP Management
          </Typography>
        </Box>
        <Box sx={{ marginLeft: "15px", marginBottom: "15px" }}>
          <Paper
            elevation={0}
            sx={{
              padding: "15px",
            }}
          >
            <Box display="flex" alignItems="center" mb={2}>
              <Link to={"/vpmanagement"}>
                <img
                  src={arrowBack}
                  alt="arroWback"
                  style={{
                    width: "2.813rem",
                    height: "2.813rem",
                    cursor: "pointer",
                  }}
                />
              </Link>
              <Typography variant="h5" sx={{ marginLeft: "1rem", fontWeight: 800  }}>
                Add Vice President
              </Typography>
            </Box>

            <Box display="flex" justifyContent="center" margin="2rem">
            <Typography variant="h6" sx={{ marginLeft: "1rem" , fontWeight: 700 }}>
            Member Details
          </Typography>
            </Box>

            <Formik
              initialValues={{
                roleType: "",
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
                state: "",
                city: "",
                address: "",
              }}
              validationSchema={vpSchema}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  const response = await postAPIHandler({
                    endPoint: "userSignup",
                    dataToSend: {
                      userType: "VP",
                      userRoleType: values.roleType,
                      firstName: values.firstName,
                      lastName: values.lastName,
                      email: values.email,
                      mobileNumber: values.phoneNumber,
                      state: values.state,
                      city: values.city,
                      address: values.address,
                    },
                  });

                  if (response && response.data.responseCode === 200) {
                    toast.success("VP added successfully.");
                    navigate("/vpmanagement");
                  } else {
                    toast.error("User already exists.");
                  }
                } catch (error) {
                  console.error("Error:", error);
                  setSubmitting(false);
                }
              }}
            >
              {({ isSubmitting, setFieldValue, values, handleBlur }) => (
                <Form>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center" 
                    justifyContent="center" 
                    spacing={2}
                  >
                    <Box mb={2} width="100%" maxWidth="500px">
                      <Box display="flex" justifyContent="space-evenly">
                        <label>
                          <strong>Role Type</strong>
                        </label>

                        <label>
                          <Field type="radio" name="roleType" value="SALES" />
                          Sales
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="roleType"
                            value="PRE_SALES"
                          />
                          Presales
                        </label>
                      </Box>
                      <ErrorMessage
                        name="roleType"
                        component="div"
                        className="error"
                      />
                    </Box>
                    <Box mb={2} width="100%" maxWidth="500px">
                      <Field
                        as={Input}
                        type="text"
                        name="firstName"
                        placeholder="Enter first name"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="error"
                      />
                    </Box>
                    <Box mb={2} width="100%" maxWidth="500px">
                      <Field
                        as={Input}
                        type="text"
                        name="lastName"
                        placeholder="Enter last name"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="error"
                      />
                    </Box>
                    <Box mb={2} width="100%" maxWidth="500px">
                      <Field
                        as={Input}
                        type="email"
                        name="email"
                        placeholder="Enter email address"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error"
                      />
                    </Box>
                    <Box mb={2} width="100%" maxWidth="500px">
                      <Field
                        as={Input}
                        type="tel"
                        name="phoneNumber"
                        placeholder="Enter phone number"
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="error"
                      />
                    </Box>
                    <Box mb={2} width="100%" maxWidth="500px">
                      <div className="s-select">
                        <Select
                          id="state"
                          name="state"
                          options={stateOptions}
                          value={stateOptions.find(
                            (option) => option.label === values.state
                          )}
                          onChange={(selectedOption) => {
                            setFieldValue(
                              "state",
                              selectedOption ? selectedOption.label : ""
                            );
                            handleStateChange(selectedOption, setFieldValue);
                          }}
                          onBlur={handleBlur}
                          isClearable={true}
                          placeholder="Select State"
                        />
                        <ErrorMessage
                          name="state"
                          component="div"
                          className="error"
                        />
                      </div>
                    </Box>
                    <Box mb={2} width="100%" maxWidth="500px">
                      <div className="s-select">
                        <Select
                          id="city"
                          name="city"
                          options={cityOptions}
                          value={cityOptions.find(
                            (option) => option.label === values.city
                          )}
                          onChange={(selectedOption) =>
                            setFieldValue(
                              "city",
                              selectedOption ? selectedOption.label : ""
                            )
                          }
                          onBlur={handleBlur}
                          isClearable={true}
                          placeholder="Select City"
                        />
                        <ErrorMessage
                          name="city"
                          component="div"
                          className="error"
                        />
                      </div>
                    </Box>
                    <Box mb={2} width="100%" maxWidth="500px">
                      <Field
                        as={Textarea}
                        name="address"
                        placeholder="Address"
                      />
                      <ErrorMessage
                        name="address"
                        component="div"
                        className="error"
                      />
                    </Box>
                    <Box
                      mb={2}
                      width="100%"
                      display="flex"
                      justifyContent="center"
                    >
                      <ButtonComponent
                        type="submit"
                        name={"Add Member"}
                        disabled={isSubmitting}
                        height="43px"
                        fullWidth
                      />
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Paper>
        </Box>
      </Box>

    </MainLayout>
  );
};

export default AddVP;
