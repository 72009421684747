import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Grid, Pagination, Tooltip } from "@mui/material";
import { getAPIHandler } from "../../config/service";
import DataNotFound from "../DataNotFound";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(0, 176, 80)",
    color: theme.palette.common.white,
    fontSize: "1rem",
    fontWeight: 400,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(even)": {
    backgroundColor: "rgba(0, 176, 80, 0.05)",
  },
  "&:last-child td, &:last-child th": {},
  "& td ": {},
}));

export default function CustomizedTables(props) {
  const [page, setPage] = React.useState(1);
  const rowsPerPage = 10; 
  const [data, setData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    
  }


  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "getLeadsFromFacebook",
      });

      if (response.data && Array.isArray(response.data.result)) {
        let propertyDetails = response.data.result;

        console.log(propertyDetails, "propertyDetails");

        const combinedLeads = [];

        propertyDetails.forEach((property) => {
          console.log("Property:", property);

          const { generatedLeads } = property.PropertyDetails || {};

          if (Array.isArray(generatedLeads)) {
            console.log("Generated Leads:", generatedLeads);
            combinedLeads.push(...generatedLeads);
          } else {
            console.warn("No Generated Leads found for property:", property);
          }
        });

        console.log("Combined Leads:", combinedLeads);
        setData(combinedLeads);
      } else {
        console.error("Invalid response structure:", response.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const extractFieldData = (fields, fieldName) => {
    const field = fields.find((item) => item.name === fieldName);
    return field && field.values && field.values.length > 0
      ? field.values[0]
      : "Not Provided";
  };

  const createRowsFromData = (data) => {
    return data.map((lead, index) => {
      const { field_data = [], created_time } = lead;

      return {
        index: index + 1,
        id: lead.id,
        fullName: extractFieldData(field_data, "full_name"),
        postCode: extractFieldData(field_data, "post_code"),
        phoneNumber: extractFieldData(field_data, "phone_number"),
        email: extractFieldData(field_data, "email"),
        preference: extractFieldData(field_data, "describe_your_preference_"),
        budget: extractFieldData(
          field_data,
          "are_you_interested_in_this_project_and_what_is_your_budget_?"
        ),
        createdTime: new Date(created_time).toLocaleString("en-IN", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "Asia/Kolkata",
        }),
      };
    });
  };

  const renderCellWithTooltip = (content, limit) => {
    if (content.length > limit) {
      return (
        <Tooltip title={content}>
          <span>{`${content.substring(0, limit)}...`}</span>
        </Tooltip>
      );
    }
    return content;
  };

  const rows = data.length > 0 ? createRowsFromData(data) : [];
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  return (
    <div>
      {data.length < 1 ? (
        <DataNotFound />
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            margin: "auto",
            boxShadow: "0 0 0px rgba(0, 0, 0, 0.1)",
            maxHeight: 650,
            scrollbarWidth: "none",
            borderRadius: "15px",
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">ID</StyledTableCell>
                <StyledTableCell align="left">Full Name</StyledTableCell>
                <StyledTableCell align="left">Phone Number</StyledTableCell>
                <StyledTableCell align="left">Email</StyledTableCell>
                <StyledTableCell align="left">Post Code</StyledTableCell>
                <StyledTableCell align="left">Preference</StyledTableCell>
                <StyledTableCell align="left">Budget</StyledTableCell>
                <StyledTableCell align="left">Created Time</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(startIndex, endIndex).map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="left">{row.id}</StyledTableCell>
                  <StyledTableCell align="left">{renderCellWithTooltip(row.fullName, 20)}</StyledTableCell>
                  <StyledTableCell align="left">
                    {row.phoneNumber}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.email}</StyledTableCell>
                  <StyledTableCell align="left">{row.postCode}</StyledTableCell>
                  <StyledTableCell align="left">
                    {row.preference}
                  </StyledTableCell>
                  <StyledTableCell align="left">{renderCellWithTooltip(row.budget,10)}</StyledTableCell>
                  <StyledTableCell align="left">
                    {row.createdTime}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
   
        <Grid container justifyContent="center" mt={3}>
          <Pagination
            color="success"
            count={Math.ceil(rows.length / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
          />
        </Grid>

    </div>
  );
}
