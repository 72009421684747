import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/MainLayout";
import { Box, Typography, Button, Paper } from "@mui/material";
import { Link, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { ApiConfig } from "../../config/ApiConfig";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import arrowBack from "../../assets/arrowBack.svg";
import toast from "react-hot-toast";
import ButtonComponent from "../../component/Button";

const sanitizeHTML = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  const unwantedTags = ['script', 'style', 'iframe', 'object', 'embed'];
  unwantedTags.forEach(tag => {
    const elements = doc.getElementsByTagName(tag);
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  });
  return doc.body.innerHTML.trim(); 
};

const EditContent = () => {
  const { id } = useParams();
  const location = useLocation();
  const [data, setData] = useState("");
  const [originalData, setOriginalData] = useState(""); 
  const [isLoading, setIsLoading] = useState(false);
 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios({
          method: "GET",
          url: `${ApiConfig.getAllStaticContentByType}?contentType=${location.state.contentType}`,
          header:{
                    token:sessionStorage.getItem('token')
                },
        });

        if (response && response?.data?.responseCode === 200) {
          const result = response.data.result;

    
          const specificContent = result.find((content) => content._id === id);
          if (specificContent) {
            if (location.state.contentType === "FAQ") {
              setData(specificContent.question + "<br />" + specificContent.answer);
              setOriginalData(specificContent.question + "<br />" + specificContent.answer);
            } else {
              setData(specificContent.description);
              setOriginalData(specificContent.description);
            }
          } else {
            toast.error("Content not found!");
          }
        }
      } catch (error) {
        console.error("An error occurred while fetching the data:", error);
      }
    };

    if (id) {
      fetchData();
    } else {
      toast.error("No content ID found in the URL.");
    }
  }, [id, location.state.contentType]);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      let payload = {
        _id: id, // Include the content ID to update the correct record
        contentType: location.state.contentType,
      };
  
      // Sanitize the content before sending it to the server
      const sanitizedData = sanitizeHTML(data);
      const replaceTag = sanitizedData ? sanitizedData.replace(/<[^>]*>/g, "") : undefined
      console.log(replaceTag, "replaceTag")
  
      if (location.state.contentType === "FAQ") {
        // For FAQ, we need to split question and answer and strip HTML tags
        const splitData = replaceTag.split("<br />");
  
        let question = splitData[0]?.trim();
        let answer = splitData[1]?.trim();
  
        // Strip HTML tags from question and answer
        question = question ? question.replace(/<[^>]*>/g, "") : undefined;
        answer = answer ? answer.replace(/<[^>]*>/g, "") : undefined;
  
        // Prepare payload for FAQ
        payload = {
          ...payload,
          question: question, 
          answer: answer,      
        };
      } else {
        // For other content types like TermsConditions, send description with HTML content
        payload = {
          ...payload,
          description: replaceTag,
        };
      }
  
      // Proceed to update if data has changed
      if (sanitizedData !== originalData) {
        const response = await axios({
          method: "POST",
          url: `${ApiConfig.updateStaticContent}`,
          data: payload,
        });
  
        if (response && response.data?.responseCode === 200) {
          toast.success("Content updated successfully!");
          setOriginalData(sanitizedData);
        } else {
          toast.error("Failed to update content. Please try again.");
        }
      } else {
        toast.error("No changes detected.");
      }
    } catch (error) {
      console.error("An error occurred while saving the data:", error);
      toast.error("An error occurred while saving the data.");
    } finally {
      setIsLoading(false);
    }
  };
  
  
  
  
  
  return (
    <MainLayout>
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" padding="1rem">
          <Typography style={{ fontWeight: 700, fontSize: "30px" }}>
            Content Management
          </Typography>
        </Box>

        <Box sx={{ marginLeft: "15px", marginBottom: "15px" }}>
          <Paper elevation={0} style={{ padding: "15px", overflowY: "auto" }}>
            <Box display="flex" alignItems="center" mb={2}>
              <Link to={"/contentmanagement"}>
                <img
                  src={arrowBack}
                  alt="arrowBack"
                  style={{
                    width: "2.813rem",
                    height: "2.813rem",
                    cursor: "pointer",
                  }}
                />
              </Link>
              <Typography variant="h5" sx={{ marginLeft: "1rem", fontWeight: 800 }}>
                Update {location.state.contentType}
              </Typography>
            </Box>

            <Box  sx={{ margin : "25px"}}><CKEditor
            editor={ClassicEditor}
            data={data}
            onChange={(event, editor) => {
              const updatedData = editor.getData();
              setData(updatedData);
            }}
            config={{
              toolbar: [
                "heading", "|", "bold", "italic", "link", "bulletedList", "numberedList", "blockQuote", "|", "undo", "redo", "alignment"
              ],
            }}
          /></Box>

            <Box mt={2} sx={{display: "flex", justifyContent : "center"}}>
              <ButtonComponent
                onClick={handleSave}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save Changes"}
              </ButtonComponent>
            </Box>
          </Paper>
        </Box>
      </Box>
    </MainLayout>
  );
};

export default EditContent;
