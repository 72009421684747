import React, { useState } from "react";
import MainLayout from "../../layout/MainLayout";
import CustomizedTables from "../../component/Table/ExecutiveManagementData";
import { Grid, Typography, Paper } from "@mui/material";
import ButtonComponent from "../../component/Button";
import Filter from "../../component/Filters/Filter";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { postAPIHandler } from "../../config/service";

const ExecutiveManagement = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // For showing loading feedback

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const handleDownloadCSV = () => {
    const downloadFunction = document.getElementById(
      "executive-tablesdata-download"
    );
    if (downloadFunction) {
      downloadFunction.click();
    }
  };

  let downloadCSVFunction = null;

  // Trigger file input click when "Upload In Bulk" is clicked
  const triggerFileInput = () => {
    document.getElementById("bulk-upload").click();
  };

  // Handle file selection and API upload
  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      toast.error("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    setIsLoading(true);

    try {
      // API call to upload file after selection is complete
      const response = await postAPIHandler({
        endPoint: "userRegistrationUsingFile", // Your user registration API
        dataToSend: formData,
      });

      if (response.status === 200) {
        toast.success("File uploaded successfully.");
      } else {
        toast.error("File upload failed.");
      }
    } catch (error) {
      console.error("Error during file upload:", error);
      toast.error("An error occurred while uploading the file.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <MainLayout>
      <Grid>
        <Grid
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: "20px",
          }}
        >
          <Typography style={{ fontWeight: 700, fontSize: "30px" }}>
            Executive Management
          </Typography>
          <Grid>
            <ButtonComponent
              name={"Download CSV"}
              borderRadius={"100px"}
              backgroundColor={"rgba(0, 0, 0, 0.05)"}
              onClick={handleDownloadCSV}
              color={"black"}
            />
            <input
              type="file"
              accept=".xlsx"
              onChange={handleFileChange} // Upload after file is selected
              style={{ display: "none" }}
              id="bulk-upload"
            />
            <ButtonComponent
              name={"Upload In Bulk"}
              borderRadius={"100px"}
              onClick={triggerFileInput} // Trigger file input when clicked
              disabled={isLoading} // Disable button while loading
            />
            <ButtonComponent
              name={"+Add Executive"}
              borderRadius={"100px"}
              onClick={() => navigate("/executivemanagement/addexecutive")}
            />
          </Grid>
        </Grid>


        <Grid style={{ marginLeft: "15px", marginBottom: "15px" }}>
          <Paper elevation={0}>
            <Grid style={{ marginBottom: "4px", padding: "15px" }}>
              <Filter
                searchPlaceholder={"Search by executive name"}
                onSearch={handleSearch}
                onFromDateChange={handleFromDateChange}
                onToDateChange={handleToDateChange}
              />
            </Grid>

            <Grid style={{ padding: "15px" }}>
              <CustomizedTables
                searchQuery={searchQuery}
                fromDate={fromDate}
                toDate={toDate}
                setDownloadCSVFunction={(func) => {
                  downloadCSVFunction = func;
                }}
              />
            </Grid>
          </Paper>
        </Grid>
        
      </Grid>
    </MainLayout>
  );
};

export default ExecutiveManagement;
