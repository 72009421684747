import React, { useContext } from "react";
import { Grid } from "@mui/material";
import Sidebar from "../component/Sidebar";
import Header from "../component/Header";
import Footer from "../component/footer/Footer";
import { AuthContext } from "../context/AuthContext";

const AlternateLayout = ({ children }) => {
  const { user } = useContext(AuthContext);
  const { fullName } = user || {};

  return (
    <Grid
      container
      direction="column"
      style={{
        minHeight: "100vh",
        backgroundColor: "rgb(250, 250, 250)",
        overflow: "hidden",
      }}
    >
      <Grid item style={{ flex: "0 0 auto" }}>
        <Header name={fullName} />
      </Grid>
      <Grid item container style={{ flex: "1 1 auto" }}>
        <Grid item xs={12} md={3} lg={2.5}>
          <Sidebar />
        </Grid>
        <Grid 
          item 
          xs={12} 
          md={9} 
          lg={9.5} 
          style={{ 
            height: "calc(100vh - 64px - 56px)", // Adjust this based on the heights of Header and Footer
            overflowY: "auto",
            overflowX: "hidden", // Prevent horizontal scrolling
            scrollbarWidth: "none", // For Firefox
          }}
        >
          {children}
        </Grid>
      </Grid>
      <Grid item style={{ flex: "0 0 auto" }}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default AlternateLayout;
