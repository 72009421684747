import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Grid, Pagination } from "@mui/material";
import LogoutPopup from "../../component/Popup/Logoutpopup";
import { useNavigate } from "react-router-dom";
import { IoEyeOutline } from "react-icons/io5";
import { AiOutlineSend } from "react-icons/ai";
import { BiEditAlt } from "react-icons/bi";
import { getAPIHandler } from "../../config/service";
import DataNotFound from "../DataNotFound";
import { format } from "date-fns";
import { saveAs } from "file-saver";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(0, 176, 80)",
    color: theme.palette.common.white,
    fontSize: "1rem",
    fontWeight: 400,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(even)": {
    backgroundColor: "rgba(0, 176, 80, 0.05)",
  },
  "&:last-child td, &:last-child th": {},
  "& td ": {},
}));

export default function CustomizedTables(props) {
  const { searchQuery, fromDate, toDate, setDownloadCSVFunction } = props;
  const [data, setData] = React.useState([]);
  const [isLogoutPopupOpen, setIsLogoutPopupOpen] = React.useState(false);
  
  const [selectedBroadcastId, setSelectedBroadcastId] = React.useState(null);
  const [totalPages, setTotalPages] = React.useState(0);

  const navigate = useNavigate();

  const handleLogoutClick = (broadcastId) => {
    setIsLogoutPopupOpen(true);
    setSelectedBroadcastId(broadcastId);
    
  };

  const handleLogoutConfirm = () => {
    setIsLogoutPopupOpen(false);
    setSelectedBroadcastId(null);
  };

  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
  });


  

  const handleChangePage = (event, value) => {
    setFilter({ ...filter, page: value });
  };


  const handleViewClick = (broadcastId) => {
    navigate(`/broadcastmanagement/viewbroadcast/${broadcastId}`);
  };

  const handleEditClick = (broadcastId) => {
    navigate(`/broadcastmanagement/editbroadcast/${broadcastId}`);
  };

  useEffect(() => {
    getData();
  }, [filter, searchQuery, fromDate, toDate]);

  useEffect(() => {
    setDownloadCSVFunction(() => handleDownloadCSV);
  }, [setDownloadCSVFunction]);

  const getData = async () => {
    try {
      const formattedFromDate = fromDate
        ? format(new Date(fromDate), "MM/dd/yyyy")
        : undefined;
      const formattedToDate = toDate
        ? format(new Date(toDate), "MM/dd/yyyy")
        : undefined;
      const paramsData = {
        ...filter,
        name: searchQuery || undefined,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
      };
      const response = await getAPIHandler(
        {
          endPoint:"listAllBroadcasts",
          paramsData,
        }
      );
      console.log("Response:", response.data);
      setData(response.data.result.broadcasts);
      setTotalPages(response.data.result.totalPages || 0);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDownloadCSV = () => {
    const headers = [
      "S.No.",
      "Title",
      "BroadCast Message",
      "User Type",
      "Created Date & Time",
      "Status",
    ];

    const csvContent = [
      headers.join(","),
      ...data.map((row, index) => [
        index + 1 + (filter.page - 1) * filter.limit,
        row._id,
        row.title,
        row.broadcastMessage,
        row.userType,
        new Date(row.createdAt).toLocaleString("en-IN", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "Asia/Kolkata",
        }),
        row.status,
      ].join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const timestamp = format(new Date(), "yyyy-MM-dd_HH:mm");
    saveAs(blob, `broadcast_list_${timestamp}.csv`);
  };

  




  const createRowsFromBroadcastManagementData = (data) => {
    if (!data) {
      return []; 
    }
    return data.map((management, index) => {
      return {
        index: index + 1 + (filter.page - 1) * filter.limit,
        _id: management._id,
        title: management.title,
        userType: management.userType,
        createdAt: new Date(management.createdAt).toLocaleString("en-IN", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "Asia/Kolkata",
        }),
        status: management.status,
      };
    });
  };


  const rows = data.length > 0 ? createRowsFromBroadcastManagementData(data) : [];

 
  return (
    <div>
    {data.length < 1 ? (
      <DataNotFound />
    ) : (
      <TableContainer
        component={Paper}
        sx={{
          margin: "auto",
          boxShadow: "0 0 0px rgba(0, 0, 0, 0.1)",
          maxHeight: 500,
          scrollbarWidth: "none",
          borderRadius: "15px",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">S.No.</StyledTableCell>
              <StyledTableCell align="middle">Title</StyledTableCell>
              <StyledTableCell align="left">User Type</StyledTableCell>
              <StyledTableCell align="left">
                Created Date & Time
              </StyledTableCell>
              <StyledTableCell align="left">Status</StyledTableCell>
              <StyledTableCell align="left" sx={{}}>
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow>
                <StyledTableCell align="left">{row.index}</StyledTableCell>

                <StyledTableCell align="left">{row.title}</StyledTableCell>
                <StyledTableCell align="left">{row.userType}</StyledTableCell>

                <StyledTableCell align="left">{row.createdAt}</StyledTableCell>
                <StyledTableCell
                  align="left"
                  style={{ color: row.status === "SENDED" ? "green" : "orange" }}
                >
                  {row.status}
                </StyledTableCell>
                <StyledTableCell>
                  <Grid
                    style={{
                      display: "flex",
                      gap: "0.7rem",
                      alignItems: "center",
                    }}
                  >
                    <IoEyeOutline
                      onClick={() => handleViewClick(row._id)}
                      style={{ fontSize: "24px", cursor: "pointer" }}
                    />
                    <BiEditAlt
                      onClick={() => handleEditClick(row._id)}
                      style={{ fontSize: "24px",cursor: "pointer" }}
                    />
                    {row.status === "PENDING" && (
                      <AiOutlineSend
                        onClick={() => handleLogoutClick(row._id)}
                        style={{ fontSize: "24px", cursor: "pointer" }}
                      />
                    )}
                  </Grid>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>)}
      {isLogoutPopupOpen && (
        <LogoutPopup
        isOpen={isLogoutPopupOpen}
        onClose={handleLogoutConfirm}
        name="Send Broadcast?"
        description="Are you sure you want to send this broadcast?"
        okbtn="Send"
        cancelbtn="No"
        broadcastId={selectedBroadcastId}
        data={data}
        setData={setData}
        type="broadcast"
        />
      )}


      {totalPages > 1 && (
        <Grid container justifyContent="center" mt={3}>
          <Pagination
            color="success"
            count={totalPages}
            page={filter.page}
            onChange={handleChangePage}
          />
        </Grid>
      )}


      <button
        id="broadcast-tablesdata-download"
        style={{ display: "none" }}
        onClick={handleDownloadCSV}
      >
        Download CSV
      </button>
    </div>
  );
}

