import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Typography, Grid, Paper, Divider } from "@mui/material";
import Box from "@mui/material/Box";
import MainLayout from "../../layout/MainLayout";
import ViewDetail from "../../component/viewDetail/ViewDetail";
import CustomizedTables from "../../component/Table/UserPropertyManagementData";
import LeadFilter from "../../component/Filters/LeadFilter";
import PropertyFilter from "../../component/Filters/PropertyFilter";
import CustomizedTable from "../../component/Table/LeadsManagementData";
import LeadAnalysisCard from "../../component/LeadCard/LeadCard";
import AssignedProperty from "../../assets/AssignedProperty.png";
import RequestedLeads from "../../assets/RequestedLeads.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import arrowBack from "../../assets/arrowBack.svg";
import DataNotFound from "../../assets/DataNotFound.png";
import { getAPIHandler } from "../../config/service";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Box sx={{ padding: "0px 10px" }}>
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function VPDetails() {
  const [value, setValue] = React.useState(0);
  const { id } = useParams();
  const [data, setData] = React.useState(null);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [status, setStatus] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [constructionStatus, setConstructionStatus] = React.useState("");
  const navigate = useNavigate();

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const handleTypeSelect = (category) => {
    setCategory(category);
  };

  const handleConstructionStatusSelect = (status) => {
    setConstructionStatus(status);
  };

  const handleStatusSelect = (status) => {
    setStatus(status);
  };

  const getData = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "viewProfile",
        paramsData: {
          userId: id,
        },
      });

      console.log("Response:", response.data);
      setData(response.data.result);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  

  React.useEffect(() => {
    getData();
  }, []);

  const executiveDetails = [
    { left: "Executive Id", value: data ? data._id : null },
    { left: "Full Name", value: data ? data.fullName : null },
    { left: "Mobile Number", value: data ? data.mobileNumber : null },
    { left: "Email Address", value: data ? data.email : null },
    { left: "Role Type", value: data ? data.userRoleType : null },
    { left: "Member Type", value: data ? data.userMemberType : null },
    {
      left: "Assigned Property",
      value: data
        ? data.assignedProperty.length > 0
          ? data.assignedProperty.join(", ")
          : "No Property Assigned"
        : null,
    },
    { left: "Created Date & Time", value: data ? data.createdAt : null },
    { left: "Status", value: data ? data.status : null },
  ];

  const hasAssignedProperties =
    data && data.assignedProperty && data.assignedProperty.length > 0;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <MainLayout>
      <Grid container alignItems="center" padding={2}>
        <Typography variant="h4" sx={{ fontWeight: 700 }}>
          Executive Management
        </Typography>
      </Grid>

      <Grid sx={{ overflowY: "auto" }} paddingX={2}>
        <Paper elevation={0}>
          <Grid container spacing={2} padding={1}>
            <Grid item xs={2}>
              <Box display="flex" alignItems="center">
                <img
                  src={arrowBack}
                  alt="Back"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(-1)}
                />
              </Box>
            </Grid>

            <Grid item xs={8}>
              <Box display="flex" justifyContent="center">
                <Tabs value={value} onChange={handleChange} centered>
                  {["General Information", "Property List", "Lead List"].map(
                    (label, index) => (
                      <Tab
                        key={index}
                        label={
                          <Typography
                            sx={{
                              textTransform: "none",
                              color:
                                value === index
                                  ? "rgba(0, 176, 80, 1)"
                                  : "#333",
                            }}
                          >
                            {label}
                          </Typography>
                        }
                      />
                    )
                  )}
                </Tabs>
              </Box>
            </Grid>
          </Grid>

          <Divider sx={{ margin: 1 }} />

          <CustomTabPanel value={value} index={0}>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              sx={{ padding: 2 }}
            >
              <Grid item xs={12} md={2}>
                <LeadAnalysisCard
                  backgroundImage={AssignedProperty}
                  heading="Assigned Property"
                  count={data ? data.assignedProperty.length : 0}
                  width="179px"
                  height="79px"
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <LeadAnalysisCard
                  backgroundImage={RequestedLeads}
                  heading="Generated Lead"
                  count={0}
                  width="179px"
                  height="79px"
                />
              </Grid>
            </Grid>

            <Grid container justifyContent="center" sx={{ marginTop: 2 }}>
              <ViewDetail type="executive" details={executiveDetails} />
            </Grid>
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            {hasAssignedProperties ? (
              <Grid>
                <Box sx={{ marginBottom: "15px" }}>
                  <PropertyFilter
                    searchPlaceholder="Search by property name..."
                    onSearch={handleSearch}
                    onFromDateChange={handleFromDateChange}
                    onToDateChange={handleToDateChange}
                    onConstructionStatusChange={handleConstructionStatusSelect}
                    onCategoryChange={handleTypeSelect}
                  />
                </Box>
                <CustomizedTables
                  userId={id}
                  searchQuery={searchQuery}
                  fromDate={fromDate}
                  toDate={toDate}
                  category={category}
                  constructionStatus={constructionStatus}
                />
              </Grid>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={DataNotFound} alt="Data Not Found" />
              </Box>
            )}
          </CustomTabPanel>

          <CustomTabPanel value={value} index={2}>
            <Box sx={{ marginBottom: "15px" }}>
              <LeadFilter
                searchPlaceholder={"Search by clientName..."}
                onSearch={handleSearch}
                onFromDateChange={handleFromDateChange}
                onToDateChange={handleToDateChange}
                onStatusChange={handleStatusSelect}
              />
            </Box>
            <CustomizedTable
              salesPersonId={id}
              searchQuery={searchQuery}
              fromDate={fromDate}
              toDate={toDate}
            />
          </CustomTabPanel>
        </Paper>
      </Grid>
    </MainLayout>
  );
}
