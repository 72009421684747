import React, { useEffect, useState } from "react";
import MainLayout from "../../../layout/MainLayout";
import { Paper, TextField, Grid, Typography, Box } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ButtonComponent from "../../../component/Button";
import "./EditProperty.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getAPIHandler,
  postAPIHandler,
  putAPIHandler,
} from "../../../config/service";
import arrowBack from "../../../assets/arrowBack.svg";
import Select from "react-select";
import { State, City } from "country-state-city";
import { propertySchema } from "../../../schemas";
import axios from "axios";
import dayjs from "dayjs";
import BubbleLoader from "../../../component/Loader/BubbleLoader";

const EditProperty = () => {
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [customImage, setCustomImage] = useState(null);
  const [uploadedFileURLs, setUploadedFileURLs] = useState([]);
  const [data, setData] = useState({});
  const [images, setImages] = useState(["https://cdn.pixabay.com/photo/2016/11/29/03/53/house-1867187_1280.jpg",]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [vpOptions, setVpOptions] = useState([]);
  const [vpType, setVpType] = useState("");
  const [filteredVpOptions, setFilteredVpOptions] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();

  const countryOptions = [{ value: "IN", label: "India" }];

  const categoryOptions = [
    { value: "Residential", label: "Residential" },
    { value: "Commercial", label: "Commercial" },
    { value: "Villa", label: "Villa" },
  ];

  const formOptions = [
    { value: "1 BHK", label: "1 BHK" },
    { value: "2 BHK", label: "2 BHK" },
    { value: "2.5 BHK", label: "2.5 BHK" },
    { value: "3 BHK", label: "3 BHK" },
    { value: "3.5 BHK", label: "3.5 BHK" },
    { value: "4 BHK", label: "4 BHK" },
    { value: "4.5 BHK", label: "4.5 BHK" },
    { value: "5 BHK", label: "5 BHK" },
    { value: "PENTHOUSE", label: "PENTHOUSE" },
    { value: "DUPLEX", label: "DUPLEX" },
    { value: "PLOT", label: "PLOT" },
    { value: "FARMHOUSE", label: "FARMHOUSE" },
    { value: "STUDIO APARTMENTS", label: "STUDIO APARTMENTS" },
    { value: "SHOP", label: "SHOP" },
    { value: "FOOD COURT", label: "FOOD COURT" },
    { value: "OFFICE SPACE", label: "OFFICE SPACE" },
    { value: "INDUSTRIAL LAND", label: "INDUSTRIAL LAND" },
    { value: "SHED", label: "SHED" },
    { value: "SCO", label: "SCO" },
  ];

  const typeOptions = [
    { value: "Under Construction", label: "Under Construction" },
    { value: "Ready to Move", label: "Ready to Move" },
  ];

  const priceOptions = [
    { value: "Lakh", label: "Lakh" },
    { value: "Crore", label: "Crore" },
  ];

  useEffect(() => {
    fetchStateOptions();
    fetchUserOptions();
  }, []);

  useEffect(() => {
    const filterVpOptions = () => {
      if (vpType === "SALES") {
        setFilteredVpOptions(vpOptions.filter((vp) => vp.type === "SALES"));
      } else if (vpType === "PRE_SALES") {
        setFilteredVpOptions(vpOptions.filter((vp) => vp.type === "PRE_SALES"));
      } else {
        setFilteredVpOptions([]);
      }
    };

    filterVpOptions();
  }, [vpType, vpOptions]);

  useEffect(() => {
    getData();
  }, []);

  const fetchStateOptions = () => {
    const states = State.getStatesOfCountry("IN").map((state) => ({
      value: state.isoCode,
      label: state.name,
    }));
    setStateOptions(states);
  };

  const fetchUserOptions = async () => {
    try {
      const vpResponse = await getAPIHandler({
        endPoint: "listUserDetailsByType",
        paramsData: { userType: "VP" },
      });

      console.log("API Response:", vpResponse.data.result.userDetails);

      const mapUsers = (users) =>
        users.map((user) => ({
          value: user._id,
          label: `${user.firstName} ${user.lastName}`,
          type: user.userRoleType ? user.userRoleType.toUpperCase() : "",
        }));

      const mappedUsers = mapUsers(vpResponse.data.result.userDetails);

      console.log("Mapped Users:", mappedUsers);

      setVpOptions(mappedUsers);
    } catch (error) {
      console.error("Error fetching user options:", error);
    }
  };

  const handleStateChange = (selectedState, setFieldValue) => {
    if (!selectedState) {
      setCityOptions([]);
      setFieldValue("city", "");
      return;
    }

    const cities = City.getCitiesOfState("IN", selectedState.value).map(
      (city) => ({
        value: city.name,
        label: city.name,
      })
    );
    setCityOptions(cities);
    setFieldValue("city", "");
  };

  const getLongLat = async (location) => {
    try {
      const response = await axios.get(
        `https://geocode.xyz/${encodeURIComponent(location)}?json=1&auth=37130815437857150226x10414`
      );
  
      const { latt: lat, longt: lon } = response.data;
      return { lat, lon };
    } catch (error) {
      console.error("Error fetching coordinates: ", error);
      return { lat: null, lon: null };
    }
  };

  const onSubmit = async (values, { setSubmitting }) => {
    setLoading(true)
    try {
      const existingImages = data.filesURL.map((file) => ({
        fileType: "IMAGE",
        url: file.url,
      }));

      const newImages = uploadedFileURLs.map((url) => ({
        fileType: "IMAGE",
        url: url,
      }));

      const allImages = [...existingImages, ...newImages];

      const { lat, lon } = await getLongLat(values.location);

      const response = await putAPIHandler({
        endPoint: "updateProperty",
        dataToSend: {
          propertyId: id,
          propertyName: values.propertyName,
          RERANumber: values.areaNumber,
          unitType: values.areaFt,
          address: values.location,
          country: values.country,
          state: values.state,
          city: values.city,
          latitude: lat, 
          longitude: lon, 
          form: values.form,
          possession: values.listedDate,
          completedTime: values.possessionDate,
          about: values.description,
          category: values.category,
          constructionStatus: values.constructionStatus,
          minPrice: `${values.minPrice} ${values.PriceDropdownMin}`,
          maxPrice: `${values.maxPrice} ${values.PriceDropdownMax}`,
          memberType: values.vpType,
          memberId: values.vpTypeOptions,
          filesURL: allImages,
        },
      });

      console.log(response.data.result);
      setSubmitting(false);

      navigate("/propertymanagement");
    } catch (error) {
      console.error("Error:", error);
      setSubmitting(false);
    }
    setLoading(false)
  };

  const getData = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "viewPropertyDetails",
        paramsData: {
          propertyId: id,
        },
      });

      console.log("Response to update the property details:", response.data);
      const propertyDetails = response.data.result[0];
      setData(propertyDetails);
      if (propertyDetails.filesURL.length > 0) {
        setImages(propertyDetails.filesURL.map((file) => file.url));
        setSelectedImage(propertyDetails.filesURL[0].url);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFileSelect = async (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        setLoading(true)
        try {
          const reader = new FileReader();
          reader.onload = () => {
            setCustomImage(reader.result);
          };
          reader.readAsDataURL(file);

          const formData = new FormData();
          formData.append("file", file);

          const res = await postAPIHandler({
            endPoint: "uploadFile",
            dataToSend: formData,
          });

          console.log("File upload response:", res);

          setUploadedFileURLs((prevURLs) => [...prevURLs, res.data.result]);
          setImages((prevImages) => [...prevImages, res.data.result]);
          setSelectedImage(res.data.result);
        } catch (error) {
          console.error("Error uploading file:", error);
        }
        setLoading(false)
      } else {
        alert("Please select an propertyImage file.");
      }
    }
  };

  const handleThumbnailClick = (index) => {
    setSelectedImage(images[index]);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setCustomImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const renderAddPhotosButtonAndInput = () => (
    <Grid>
    <ButtonComponent
    height="43px"
    onClick={() => document.getElementById("imageInput").click()}
  >
    {loading ? (
      <BubbleLoader color="#FFF" size="0.7rem" />
    ) : (
      "Upload Images"
    )}{" "}
  </ButtonComponent>
      <input
        id="imageInput"
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleFileSelect}
      />
    </Grid>
  );

 
  return (
    <MainLayout>
      <Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            padding: "1rem",
          }}
        >
          <Typography style={{ fontWeight: 700, fontSize: "30px" }}>
            Property Management
          </Typography>
        </Grid>

        <Grid style={{ marginLeft: "15px", marginBottom: "15px" }}>
          <Paper
            elevation={0}
            style={{
              padding: "1rem",
              overflowY: "auto",
              scrollbarWidth: "none",
            }}
          >
            <div className="addproperty-header">
              <div className="arrow">
                <Link to={"/propertymanagement"}>
                  <img
                    src={arrowBack}
                    alt="arroWback"
                    style={{
                      width: "2.813rem",
                      height: "2.813rem",
                      cursor: "pointer",
                    }}
                  />
                </Link>
                <Typography
                variant="h5"
                sx={{ marginLeft: "1rem", fontWeight: 800 }}
              >
                Update Property
              </Typography>
              </div>
            </div>

            <Box display="flex" justifyContent="center" margin="2rem">
              <Typography
                variant="h6"
                sx={{ marginLeft: "1rem", fontWeight: 700 }}
              >
                Property Details
              </Typography>
            </Box>

            <div className="add-property-container">
              <div className="add-property-content">
                <div
                  className="image-section"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                >
                  <div className="main-image-container">
                    {customImage || selectedImage ? (
                      <img src={customImage || selectedImage} alt="Custom" style={{ width: '100%', maxWidth: '600px' }}/>
                    ) : (
                      <Grid>
                        <div className="upload-overlay">
                          <span className="plus-icon">+</span>
                          <strong className="upload-message">
                            Drag and drop images to upload
                          </strong>
                        </div>
                        <img
                          src={images[selectedImage]}
                          alt="Custom"
                          style={{ width: '100%', maxWidth: '600px' }}
                          onDoubleClick={() =>
                            document.getElementById("imageInput").click()
                          }
                        />
                      </Grid>
                    )}
                  </div>
                  {images.length > 1 && (
                    <div className="thumbnails">
                      {images.map((image, index) => (
                        <img
                          key={index}
                          src={image}
                          alt="Thumbnail"
                          className={index === selectedImage ? "active" : ""}
                          onClick={() => handleThumbnailClick(index)}
                        />
                      ))}
                    </div>
                  )}
                  {renderAddPhotosButtonAndInput()}
                </div>
              </div>

              <div className="add-property-content">
                <Formik
                initialValues={{
                  propertyName: data ? data.propertyName : "",
                  areaNumber: data ? data.RERANumber : "",
                  areaFt: data ? data.unitType : "",
                  country: data ? data.country : "",
                  state: data ? data.state : "",
                  city: data ? data.city : "",
                  category: data ? data.category : "",
                  form: data ? data.form : "",
                  constructionStatus: data ? data.constructionStatus : "",
                  listedDate: data ? data.possession : "",
                  possessionDate: data ? data.completedTime : "",
                  location: data ? data.address : "",
                  minPrice: data ? data?.minPrice?.split(" ")[0] : "", 
                  maxPrice: data ? data?.maxPrice?.split(" ")[0] : "",
                  PriceDropdownMin: data ? data?.minPrice?.split(" ")[1] : "", 
                  PriceDropdownMax: data ? data?.maxPrice?.split(" ")[1] : "",
                  description: data ? data.about : "",
                  vpType: data ? data.memberType : "",
                  vpTypeOptions: data ? data.memberId : "",
                  filesURL: data ? data.filesURL : [],
              }}
              
                  enableReinitialize={true}
                  validationSchema={propertySchema}
                  onSubmit={onSubmit}
                >
                  {({ values, setFieldValue, handleBlur, handleChange }) => (
                    <Form>
                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              Property Name{" "}
                              <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Field
                              as={TextField}
                              type="text"
                              name="propertyName"
                              variant="outlined"
                              placeholder="Enter property name"
                              className="form-control"
                              onKeyPress={(e) => {
                                if (e.target.value.length === 0) {
                                  if (e.key === " ") e.preventDefault();
                                }
                                if (
                                  e.target.value.length >= 60 &&
                                  e.key !== "Backspace"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            <ErrorMessage
                              name="propertyName"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              RERA Number{" "}
                              <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Field
                              as={TextField}
                              type="text"
                              name="areaNumber"
                              variant="outlined"
                              placeholder="Enter RERA number"
                              className="form-control"
                              onKeyPress={(e) => {
                                if (e.target.value.length === 0) {
                                  if (e.key === " ") e.preventDefault();
                                }
                                if (
                                  e.target.value.length >= 60 &&
                                  e.key !== "Backspace"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            <ErrorMessage
                              name="areaNumber"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              Area in(sq ft.){" "}
                              <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Field
                              as={TextField}
                              type="text"
                              name="areaFt"
                              variant="outlined"
                              placeholder="Area in(sq ft.)"
                              className="form-control"
                              onKeyPress={(e) => {
                                if (e.target.value.length === 0) {
                                  if (e.key === " ") e.preventDefault();
                                }
                                if (
                                  e.target.value.length >= 60 &&
                                  e.key !== "Backspace"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            <ErrorMessage
                              name="areaFt"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              Country <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Select
                              className="select"
                              name="country"
                              options={countryOptions}
                              value={countryOptions.find(
                                (option) => option.label === values.country
                              )}
                              onChange={(selectedOption) =>
                                setFieldValue(
                                  "country",
                                  selectedOption ? selectedOption.label : ""
                                )
                              }
                              onBlur={handleBlur}
                              isClearable={true}
                              placeholder="Select country"
                              // isDisabled={true} // Makes the field non-editable
                            />
                            <ErrorMessage
                              name="country"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              State <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Select
                              className="select"
                              name="state"
                              options={stateOptions}
                              value={stateOptions.find(
                                (option) => option.label === values.state
                              )}
                              onChange={(selectedOption) => {
                                setFieldValue(
                                  "state",
                                  selectedOption ? selectedOption.label : ""
                                );
                                handleStateChange(
                                  selectedOption,
                                  setFieldValue
                                );
                              }}
                              onBlur={handleBlur}
                              isClearable={true}
                              placeholder="Select state"
                            />
                            <ErrorMessage
                              name="state"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              City <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Select
                              className="select"
                              name="city"
                              options={cityOptions}
                              value={cityOptions.find(
                                (option) => option.label === values.city
                              )}
                              onChange={(selectedOption) =>
                                setFieldValue(
                                  "city",
                                  selectedOption ? selectedOption.label : ""
                                )
                              }
                              // isClearable={true}
                              placeholder="Select city"
                            />
                            <ErrorMessage
                              name="city"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              Category <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Select
                              className="select"
                              name="category"
                              options={categoryOptions}
                              value={categoryOptions.find(
                                (option) => option.value === values.category
                              )}
                              onChange={(selectedOption) =>
                                setFieldValue(
                                  "category",
                                  selectedOption ? selectedOption.value : ""
                                )
                              }
                              onBlur={handleBlur}
                              isClearable={true}
                              placeholder="Select Category"
                            />
                            <ErrorMessage
                              name="category"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              Form <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Select
                              className="select"
                              name="form"
                              options={formOptions}
                              value={formOptions.find(
                                (option) => option.value === values.form
                              )}
                              onChange={(selectedOption) =>
                                setFieldValue(
                                  "form",
                                  selectedOption ? selectedOption.value : ""
                                )
                              }
                              onBlur={handleBlur}
                              isClearable={true}
                              placeholder="Select Form"
                            />
                            <ErrorMessage
                              name="form"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              Construction Status
                              <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Select
                              className="select"
                              name="constructionStatus"
                              options={typeOptions}
                              value={typeOptions.find(
                                (option) =>
                                  option.value === values.constructionStatus
                              )}
                              onChange={(selectedOption) =>
                                setFieldValue(
                                  "constructionStatus",
                                  selectedOption ? selectedOption.value : ""
                                )
                              }
                              onBlur={handleBlur}
                              isClearable={true}
                              placeholder="Select construction status"
                            />
                            <ErrorMessage
                              name="constructionStatus"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                            Possession<span style={{ color: "red" }}>*</span>{" "}
                              :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  name="possessionDate"
                                  sx={{
                                    width: "23rem", // Set desired width
                                    height: "2.4", // Set desired height
                                    "& .MuiInputBase-input": {
                                      // Target the input field
                                      fontSize: "15px", // Adjust font size if needed
                                      padding: "0.7rem",
                                    },
                                  }}
                                  value={values.listedDate ? dayjs(values.listedDate) : null} // Ensure valid date object
                                  onChange={(newDate) => setFieldValue("listedDate", newDate)}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </DemoContainer>
                            </LocalizationProvider>{" "}
                            <ErrorMessage
                              name="possessionDate"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                            Compeleted Time
                              <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  name="listedDate"
                                  disablePast
                                  sx={{
                                    width: "23rem", // Set desired width
                                    height: "2.4", // Set desired height
                                    "& .MuiInputBase-input": {
                                      // Target the input field
                                      fontSize: "15px", // Adjust font size if needed
                                      padding: "0.7rem",
                                    },
                                  }}
                                  value={values.possessionDate ? dayjs(values.possessionDate) : null} // Ensure valid date object
                                  onChange={(newDate) => setFieldValue("possessionDate", newDate)}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                            <ErrorMessage
                              name="listedDate"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              location <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Field
                              as={TextField}
                              type="text"
                              name="location"
                              variant="outlined"
                              className="form-control"
                              onKeyPress={(e) => {
                                if (e.target.value.length === 0) {
                                  if (e.key === " ") e.preventDefault();
                                }
                                if (
                                  e.target.value.length >= 60 &&
                                  e.key !== "Backspace"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(event) => {
                                handleChange(event);
                                getLongLat(event, setFieldValue);
                              }}
                            />
                            <ErrorMessage
                              name="location"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              Price Range<span style={{ color: "red" }}>*</span>{" "}
                              :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <div className="price-range">
                              <div className="price-content">
                                <span>Min:</span>
                                <Field
                                  as={TextField}
                                  type="number"
                                  name="minPrice"
                                  variant="outlined"
                                  className="form-price"
                                  onKeyPress={(e) => {
                                    if (e.target.value.length === 0) {
                                      if (e.key === " ") e.preventDefault();
                                    }
                                    if (
                                      e.target.value.length >= 60 &&
                                      e.key !== "Backspace"
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                  value={values.minPrice}
                                />
                                <Select
                                  className="price-dropdown"
                                  name="PriceDropdownMin"
                                  options={priceOptions}
                                  value={priceOptions.find(
                                    (option) => option.value === values.PriceDropdownMin
                                  )}
                                  onChange={(selectedOption) =>
                                    setFieldValue(
                                      "PriceDropdownMin",
                                      selectedOption ? selectedOption.value : ""
                                    )
                                  }
                                  onBlur={handleBlur}
                                  isClearable={true}
                                  placeholder="Unit"
                                />
                              </div>

                              <div className="price-content">
                                <span>Max:</span>
                                <Field
                                  as={TextField}
                                  type="number"
                                  name="maxPrice"
                                  variant="outlined"
                                  className="form-price"
                                  onKeyPress={(e) => {
                                    if (e.target.value.length === 0) {
                                      if (e.key === " ") e.preventDefault();
                                    }
                                    if (
                                      e.target.value.length >= 60 &&
                                      e.key !== "Backspace"
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                  value={values.maxPrice}
                                />
                                <Select
                                  className="price-dropdown"
                                  name="PriceDropdownMax"
                                  options={priceOptions}
                                  value={priceOptions.find(
                                    (option) => option.value === values.PriceDropdownMax
                                  )}
                                  onChange={(selectedOption) =>
                                    setFieldValue(
                                      "PriceDropdownMax",
                                      selectedOption ? selectedOption.value : ""
                                    )
                                  }
                                  onBlur={handleBlur}
                                  isClearable={true}
                                  placeholder="Unit"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="developer-title">
                          <label htmlFor="description">
                            <strong>About:</strong>
                          </label>
                          <div className="errordiv">
                            <Field
                              as={TextField}
                              multiline
                              rows={4}
                              name="description"
                              variant="outlined"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="description"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="developer-title">
                          <label htmlFor="vpType">
                            <strong>
                              Assign VP<span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                          <div className="radio-buttons">
                          <label>
                            <Field
                              type="radio"
                              name="vpType"
                              value="SALES"
                              checked={values.vpType === "SALES"} // Ensure correct selection
                              onClick={() => {
                                setVpType("SALES");
                                setFieldValue("vpType", "SALES"); // Ensure form state is updated
                              }}
                            />
                            Sales
                          </label>
                          <label>
                            <Field
                              type="radio"
                              name="vpType"
                              value="PRE_SALES"
                              checked={values.vpType === "PRE_SALES"} // Ensure correct selection
                              onClick={() => {
                                setVpType("PRE_SALES");
                                setFieldValue("vpType", "PRE_SALES"); // Ensure form state is updated
                              }}
                            />
                            Presales
                          </label>
                        </div>
                        
                            <ErrorMessage
                              name="vpType"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>
                      {values.vpType && (
                        <div className="form-group">
                          <div className="developer-title">
                            <label htmlFor="vpTypeOptions">
                              <strong>Select Member:</strong>
                            </label>

                            <div className="errordiv">
                              <Select
                                className="select"
                                name="vpTypeOptions"
                                options={filteredVpOptions}
                                value={filteredVpOptions.find(
                                  (option) =>
                                    option.value === values.vpTypeOptions
                                )}
                                onChange={(selectedOption) =>
                                  setFieldValue(
                                    "vpTypeOptions",
                                    selectedOption ? selectedOption.value : ""
                                  )
                                }
                                // onBlur={handleBlur}
                                isClearable={true}
                                placeholder="Select Member"
                              />
                              <ErrorMessage
                                name="vpTypeOptions"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="button-container">
                        <ButtonComponent type="submit" height="43px" > {loading ? <BubbleLoader color="#FFF" size="0.7rem" /> : "Update Property"} </ButtonComponent>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default EditProperty;
