import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Grid, Pagination, Tooltip } from "@mui/material";
import LogoutPopup from "../../component/Popup/Logoutpopup";
import { useNavigate } from "react-router-dom";
import { IoEyeOutline } from "react-icons/io5";
import { BiEditAlt } from "react-icons/bi";
import { BiBlock } from "react-icons/bi";
import { getAPIHandler } from "../../config/service";
import DataNotFound from "../DataNotFound";
import { format } from "date-fns";
import { saveAs } from "file-saver";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(0, 176, 80)",
    color: theme.palette.common.white,
    fontSize: "1rem",
    fontWeight: 400,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(even)": {
    backgroundColor: "rgba(0, 176, 80, 0.05)",
  },
  "&:last-child td, &:last-child th": {},
  "& td ": {},
}));

export default function CustomizedTables(props) {
  const { searchQuery, fromDate, toDate, setDownloadCSVFunction } = props;
  const [totalPages, setTotalPages] = useState(0);
  const [isLogoutPopupOpen, setIsLogoutPopupOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserStatus, setSelectedUserStatus] = useState(null);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({ page: 1, limit: 10 });

  const handleLogoutClick = (id, status) => {
    setIsLogoutPopupOpen(true);
    setSelectedUserId(id);
    setSelectedUserStatus(status);
  };

  const handleLogoutConfirm = () => {
    setIsLogoutPopupOpen(false);
    setSelectedUserId(null);
  };
  const navigate = useNavigate();

  const handleChangePage = (event, value) => {
    setFilter({ ...filter, page: value });
  };

  const handleViewClick = (managerId) => {
    navigate(`/managermanagement/managerdetail/${managerId}`);
  };

  const handleEditClick = (managerId) => {
    navigate(`/managermanagement/editmanager/${managerId}`);
  };

  const getData = async () => {
    try {
      const formattedFromDate = fromDate
        ? format(new Date(fromDate), "yyyy-MM-dd")
        : undefined;
      const formattedToDate = toDate
        ? format(new Date(toDate), "yyyy-MM-dd")
        : undefined;

      const paramsData = {
        ...filter,
        name: searchQuery || undefined,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        userType: "MANAGER",
      };

      const managerResponse = await getAPIHandler({
        endPoint: "listUserDetailsByType",
        paramsData,
      });

      const managerData = managerResponse.data.result.userDetails;

      const execCountPromises = managerData.map((manager) =>
        getAPIHandler({
          endPoint: "listExecutivesByManagerOrVP",
          paramsData: { managerId: manager._id },
        })
      );

      const execCountResponses = await Promise.all(execCountPromises);
      console.log("Executive Counts:", execCountResponses);

      const execCounts = execCountResponses.map((response) => {
        const executiveDetails = response.data?.result?.executiveDetails || [];
        return executiveDetails.length;
      });

      const mergedData = managerData.map((manager, index) => ({
        ...manager,
        NoOfAssignedExecutive: execCounts[index],
      }));

      setData(mergedData);
      setTotalPages(managerResponse.data.result.totalPages || 0);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getData();
  }, [filter, searchQuery, fromDate, toDate]);

  useEffect(() => {
    setDownloadCSVFunction(() => handleDownloadCSV);
  }, [setDownloadCSVFunction]);

  const handleDownloadCSV = () => {
    const headers = [
      "S.No.",
      "Id",
      "Name",
      "Mobile no.",
      "Email",
      "Number of Assigened Executive",
      "Created Date & Time",
      "Status",
      "Added By",
    ];

    const csvContent = [
      headers.join(","),
      ...data.map((row, index) =>
        [
          index + 1 + (filter.page - 1) * filter.limit,
          row._id,
          row.fullName,
          row.mobileNumber,
          row.email,
          row.NoOfAssignedExecutive,
          new Date(row.createdAt).toLocaleString("en-IN", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            timeZone: "Asia/Kolkata",
          }),
          row.status,
          row.userRoleType,
        ].join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const timestamp = format(new Date(), "yyyy-MM-dd_HH:mm");
    saveAs(blob, `manager_list_${timestamp}.csv`);
  };

  const renderCellWithTooltip = (content, limit) => {
    if (content.length > limit) {
      return (
        <Tooltip title={content}>
          <span>{`${content.substring(0, limit)}...`}</span>
        </Tooltip>
      );
    }
    return content;
  };

  const createRowsFromManagerManagementData = (data) => {
    return data.map((management, index) => {
      const lastSixDigits = management._id.slice(-6);
      return {
        index: index + 1,
        _id: management._id,
        managerId: lastSixDigits,
        fullName: management.fullName,
        mobileNumber: management.mobileNumber,
        email: management.email,
        NoOfAssignedExecutive: management.NoOfAssignedExecutive,
        createdAt: new Date(management.createdAt).toLocaleString("en-IN", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "Asia/Kolkata",
        }),
        status: management.status,
        userRoleType: management.userRoleType,
      };
    });
  };

  const rows = data.length > 0 ? createRowsFromManagerManagementData(data) : [];

  return (
    <div>
    {data.length < 1 ? (
      <DataNotFound />
    ) : (
    
      <TableContainer
        component={Paper}
        sx={{
          margin: "auto",
          boxShadow: "0 0 0px rgba(0, 0, 0, 0.1)",
          maxHeight: "500px",
          scrollbarWidth: "none",
          borderRadius: "15px",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">S.No.</StyledTableCell>
              <StyledTableCell align="left">Manager ID</StyledTableCell>
              <StyledTableCell align="left">Name</StyledTableCell>
              <StyledTableCell align="left">Mobile no.</StyledTableCell>
              <StyledTableCell align="left">Email</StyledTableCell>
              <StyledTableCell align="left">#Executive</StyledTableCell>
              <StyledTableCell align="left">
                Created Date & Time
              </StyledTableCell>
              <StyledTableCell align="left">Status</StyledTableCell>
              <StyledTableCell align="left">Added By</StyledTableCell>
              <StyledTableCell align="left">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row._id}>
                <StyledTableCell align="left">{row.index}</StyledTableCell>
                <StyledTableCell align="left">{row.managerId}</StyledTableCell>
                <StyledTableCell align="left">{row.fullName}</StyledTableCell>
                <StyledTableCell align="left">
                  {row.mobileNumber}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {renderCellWithTooltip(row.email, 20)}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {row.NoOfAssignedExecutive}
                </StyledTableCell>

                <StyledTableCell align="left">{row.createdAt}</StyledTableCell>
                <StyledTableCell
                  align="left"
                  style={{ color: row.status === "ACTIVE" ? "green" : "red" }}
                >
                  {row.status}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {row.userRoleType}
                </StyledTableCell>
                <StyledTableCell>
                  <Grid
                    style={{
                      display: "flex",
                      gap: "0.7rem",
                      alignItems: "center",
                    }}
                  >
                    <IoEyeOutline
                      onClick={() => handleViewClick(row._id)}
                      style={{ fontSize: "24px" }}
                    />
                    <BiEditAlt
                      onClick={() => handleEditClick(row._id)}
                      style={{ fontSize: "24px" }}
                    />
                    <BiBlock
                      onClick={() => {
                        handleLogoutClick(row._id, row.status);
                      }}
                      style={{
                        color: row.status === "ACTIVE" ? "green" : "red",
                        fontSize: "24px",
                      }}
                    />
                  </Grid>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>)}
      {isLogoutPopupOpen && (
        <LogoutPopup
          isOpen={isLogoutPopupOpen}
          onClose={handleLogoutConfirm}
          name={
            selectedUserStatus === "ACTIVE"
              ? "Block Manager?"
              : "Unblock Manager?"
          }
          description={
            selectedUserStatus === "ACTIVE"
              ? "Are you sure you want to block this manager?"
              : "Are you sure you want to unblock this manager?"
          }
          cancelbtn="No"
          okbtn={selectedUserStatus === "ACTIVE" ? "Block" : "Unblock"}
          route="manager"
          userId={selectedUserId}
          data={data}
          setData={setData}
          type="user"
        />
      )}

      {totalPages > 1 && (
        <Grid container justifyContent="center" mt={3}>
          <Pagination
            color="success"
            count={totalPages}
            page={filter.page}
            onChange={handleChangePage}
          />
        </Grid>
      )}

      <button
        id="manager-tablesdata-download"
        style={{ display: "none" }}
        onClick={handleDownloadCSV}
      >
        Download CSV
      </button>
    </div>
  );
}
