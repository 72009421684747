import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import MainLayout from "../../../layout/MainLayout";
import { Paper, Grid, Typography, Box } from "@mui/material";
import arrowBack from "../../../assets/arrowBack.svg";
import Input from "@mui/joy/Input";
import Textarea from "@mui/joy/Textarea";
import Select from "react-select";
import "./UpdateExecutive.css";
import ButtonComponent from "../../../component/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAPIHandler, putAPIHandler } from "../../../config/service";
import { IoIosAddCircle, IoIosRemoveCircle } from "react-icons/io";
import { executiveSchema } from "../../../schemas";
import { State, City } from "country-state-city";

const EditExecutive = () => {
  const [data, setData] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [vpOptions, setVpOptions] = useState([]);
  const [managerOptions, setManagerOptions] = useState([]);
  const [propertyOptions, setPropertyOptions] = useState([]);
  const [additionalProjects, setAdditionalProjects] = useState([]);
  const [showAdditionalProjects, setShowAdditionalProjects] = useState(false);

  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    fetchStateOptions();
    fetchUserOptions();
    fetchPropertyOptions();
  }, []);

  const fetchStateOptions = () => {
    const states = State.getStatesOfCountry("IN").map((state) => ({
      value: state.isoCode,
      label: state.name,
    }));
    setStateOptions(states);
  };

  const fetchUserOptions = async () => {
    try {
      const vpResponse = await getAPIHandler({
        endPoint: "listUserDetailsByType",
        paramsData: { userType: "VP" },
      });
      const managerResponse = await getAPIHandler({
        endPoint: "listUserDetailsByType",
        paramsData: { userType: "MANAGER" },
      });

      const mapUsers = (users) =>
        users.map((user) => ({
          value: user._id,
          label: `${user.firstName} ${user.lastName}`,
          role: user.userRoleType,
        }));

      setVpOptions(mapUsers(vpResponse.data.result.userDetails));
      setManagerOptions(mapUsers(managerResponse.data.result.userDetails));
    } catch (error) {
      console.error("Error fetching user options:", error);
    }
  };

  const fetchPropertyOptions = async () => {
    try {
      const propertyResponse = await getAPIHandler({
        endPoint: "getAllProperty",
      });

      const mapProperties = (properties) =>
        properties.map((property) => ({
          value: property._id,
          label: property.propertyName,
        }));

      setPropertyOptions(mapProperties(propertyResponse.data.result.property));
    } catch (error) {
      console.error("Error fetching property options:", error);
    }
  };

  const handleStateChange = (selectedState, setFieldValue) => {
    if (!selectedState) {
      setCityOptions([]);
      setFieldValue("city", "");
      return;
    }

    const cities = City.getCitiesOfState("IN", selectedState.value).map(
      (city) => ({
        value: city.name,
        label: city.name,
      })
    );
    setCityOptions(cities);
    setFieldValue("city", "");
  };

  const handleRemoveProject = (index) => {
    const updatedProjects = additionalProjects.filter((_, i) => i !== index);
    setAdditionalProjects(updatedProjects);
  };

  const handleAddProject = () => {
    setAdditionalProjects([...additionalProjects, { project: "" }]);
    setShowAdditionalProjects(true);
  };

  const handleProjectChange = (index, selectedOption, setFieldValue) => {
    const updatedProjects = [...additionalProjects];
    updatedProjects[index] = {
      project: selectedOption ? selectedOption.value : "",
    };
    setAdditionalProjects(updatedProjects);
    setFieldValue(
      `additionalProjects[${index}]`,
      selectedOption ? selectedOption.value : ""
    );
  };

  const onSubmit = async (values, { setSubmitting }) => {
    const assignedProperty = [
      values.project,
      ...values.additionalProjects,
    ].filter(Boolean);
    const dataToSend = {
      userId: id,
      userType: "EXECUTIVE",
      userRoleType: values.roleType,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      mobileNumber: values.phoneNumber,
      address: values.address,
      state: values.state,
      city: values.city,
      managerId: values.manager,
      vpId: values.vp,
      assignedProperty: assignedProperty.map((p) =>
        typeof p === "object" ? p.project : p
      ),
    };
    if (values.roleType === "PRE_SALES" || values.roleType === "BOTH") {
      dataToSend.userMemberType = values.memberType;
    }
    try {
      const response = await putAPIHandler({
        endPoint: "updateProfile",
        dataToSend,
      });

      console.log(response.data.result);
      setSubmitting(false);

      navigate("/executivemanagement");
    } catch (error) {
      console.error("Error:", error);
      setSubmitting(false);
    }
  };

  const getData = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "viewProfile",
        paramsData: {
          userId: id,
        },
      });
      console.log("Response:", response.data);
      const userData = response.data.result;
      const project =
        userData.assignedProperty.length > 0
          ? userData.assignedProperty[0]
          : "";
      const additionalProjects =
        userData.assignedProperty.length > 1
          ? userData.assignedProperty
              .slice(1)
              .map((property) => ({ project: property }))
          : [];

      setData({
        ...userData,
        project,
        additionalProjects,
      });
      setAdditionalProjects(additionalProjects);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <MainLayout>
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" padding="1rem">
          <Typography style={{ fontWeight: 700, fontSize: "30px" }}>
            Executive Management
          </Typography>
        </Box>
        <Box sx={{ marginLeft: "15px", marginBottom: "15px" }}>
          <Paper
            elevation={0}
            style={{
              padding: "15px",
              overflowY: "auto",
              scrollbarWidth: "none",
            }}
          >
            <Box display="flex" alignItems="center" mb={2}>
              <Link to={"/executivemanagement"}>
                <img
                  src={arrowBack}
                  alt="arroWback"
                  style={{
                    width: "2.813rem",
                    height: "2.813rem",
                    cursor: "pointer",
                  }}
                />
              </Link>
              <Typography
                variant="h5"
                sx={{ marginLeft: "1rem", fontWeight: 800 }}
              >
                Update Executive
              </Typography>
            </Box>

            <Box display="flex" justifyContent="center" margin="2rem">
              <Typography
                variant="h6"
                sx={{ marginLeft: "1rem", fontWeight: 700 }}
              >
                Member Details
              </Typography>
            </Box>

            <Formik
              initialValues={{
                roleType: data ? data.userRoleType : "",
                memberType: data ? data.userMemberType : "",
                firstName: data ? data.firstName : "",
                lastName: data ? data.lastName : "",
                email: data ? data.email : "",
                phoneNumber: data ? data.mobileNumber : "",
                state: data ? data.state : "",
                city: data ? data.city : "",
                address: data ? data.address : "",
                vp: data ? data.vpId : "",
                manager: data ? data.managerId : "",
                project: data ? data.project : "",
                additionalProjects: data ? data.additionalProjects : [],
              }}
              enableReinitialize={true}
              validationSchema={executiveSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, setFieldValue, values, handleBlur }) => (
                <Form>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                  >
                    <Box mb={2} width="100%" maxWidth="500px">
                      <Box display="flex" justifyContent="space-evenly">
                        <label htmlFor="roleType">
                          <strong>Role Type</strong>
                        </label>
                        <Field
                          type="radio"
                          name="roleType"
                          id="roleType"
                          value="SALES"
                        />
                        <label htmlFor="roleType">Sales</label>
                          <Field
                            type="radio"
                            name="roleType"
                            value="PRE_SALES"
                          />
                          <label htmlFor="roleType">Presales</label>
                      
                        
                          <Field
                            type="radio"
                            name="roleType"
                            id="roleType"
                            value="BOTH"
                          />
                          <label htmlFor="roleType">Both (Sales + Presales)</label>
                     
                      </Box>
                      <ErrorMessage
                        name="roleType"
                        component="div"
                        id="roleType"
                        className="error"
                      />
                    </Box>
                    {(values.roleType === "PRE_SALES" ||
                      values.roleType === "BOTH") && (
                      <Box mb={2} width="100%" maxWidth="500px">
                        <Box display="flex" justifyContent="space-evenly">
                          <label htmlFor="memberType">
                            <strong>Member Type</strong>
                          </label>
                          <Field
                            type="radio"
                            id="memberType"
                            name="memberType"
                            value="INTERNAL"
                          />
                          <label htmlFor="memberType">Internal</label>
                          <Field
                            type="radio"
                            id="memberType"
                            name="memberType"
                            value="EXTERNAL"
                          />
                          <label htmlFor="memberType">External</label>
                        </Box>
                        <ErrorMessage
                          name="memberType"
                          component="div"
                          className="error"
                        />
                      </Box>
                    )}
                    <Box mb={2} width="100%" maxWidth="500px">
                      <Field
                        as={Input}
                        type="text"
                        name="firstName"
                        placeholder="Enter first name"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="error"
                      />
                    </Box>
                    <Box mb={2} width="100%" maxWidth="500px">
                      <Field
                        as={Input}
                        type="text"
                        name="lastName"
                        placeholder="Enter last name"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="error"
                      />
                    </Box>
                    <Box mb={2} width="100%" maxWidth="500px">
                      <Field
                        as={Input}
                        type="email"
                        name="email"
                        placeholder="Enter email address"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error"
                      />
                    </Box>
                    <Box mb={2} width="100%" maxWidth="500px">
                      <Field
                        as={Input}
                        type="tel"
                        name="phoneNumber"
                        placeholder="Enter phone number"
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="error"
                      />
                    </Box>
                    <Box mb={2} width="100%" maxWidth="500px">
                      <div className="s-select">
                        <Select
                          id="state"
                          name="state"
                          options={stateOptions}
                          value={stateOptions.find(
                            (option) => option.label === values.state
                          )}
                          onChange={(selectedOption) => {
                            setFieldValue(
                              "state",
                              selectedOption ? selectedOption.label : ""
                            );
                            handleStateChange(selectedOption, setFieldValue);
                          }}
                          onBlur={handleBlur}
                          isClearable={true}
                          placeholder="Select State"
                        />
                        <ErrorMessage
                          name="state"
                          component="div"
                          className="error"
                        />
                      </div>
                    </Box>
                    <Box mb={2} width="100%" maxWidth="500px">
                      <div className="s-select">
                        <Select
                          id="city"
                          name="city"
                          options={cityOptions}
                          value={cityOptions.find(
                            (option) => option.label === values.city
                          )}
                          onChange={(selectedOption) =>
                            setFieldValue(
                              "city",
                              selectedOption ? selectedOption.label : ""
                            )
                          }
                          onBlur={handleBlur}
                          isClearable={true}
                          placeholder="Select City"
                        />
                        <ErrorMessage
                          name="city"
                          component="div"
                          className="error"
                        />
                      </div>
                    </Box>
                    <Box mb={2} width="100%" maxWidth="500px">
                      <Field
                        as={Textarea}
                        name="address"
                        placeholder="Address"
                      />
                      <ErrorMessage
                        name="address"
                        component="div"
                        className="error"
                      />
                    </Box>
                    <Box mb={2} width="100%" maxWidth="500px">
                      <div className="s-select">
                        <Select
                          id="vp"
                          name="vp"
                          options={
                            values.roleType === "SALES"
                              ? vpOptions.filter(
                                  (option) => option.role === "SALES"
                                )
                              : values.roleType === "PRE_SALES"
                              ? vpOptions.filter(
                                  (option) => option.role === "PRE_SALES"
                                )
                              : values.roleType === "BOTH"
                              ? vpOptions
                              : []
                          }
                          value={vpOptions.find(
                            (option) => option.value === values.vp
                          )}
                          onChange={(selectedOption) =>
                            setFieldValue(
                              "vp",
                              selectedOption ? selectedOption.value : ""
                            )
                          }
                          onBlur={handleBlur}
                          isClearable={true}
                          getOptionLabel={(option) => option.label}
                          placeholder="Select VP"
                        />
                        <ErrorMessage
                          name="vp"
                          component="div"
                          className="error"
                        />
                      </div>
                    </Box>
                    <Box mb={2} width="100%" maxWidth="500px">
                      <div className="s-select">
                        <Select
                          id="manager"
                          name="manager"
                          options={
                            values.roleType === "SALES"
                              ? managerOptions.filter(
                                  (option) => option.role === "SALES"
                                )
                              : values.roleType === "PRE_SALES"
                              ? managerOptions.filter(
                                  (option) => option.role === "PRE_SALES"
                                )
                              : values.roleType === "BOTH"
                              ? managerOptions
                              : []
                          }
                          value={managerOptions.find(
                            (option) => option.value === values.manager
                          )}
                          onChange={(selectedOption) =>
                            setFieldValue(
                              "manager",
                              selectedOption ? selectedOption.value : ""
                            )
                          }
                          onBlur={handleBlur}
                          isClearable={true}
                          getOptionLabel={(option) => option.label}
                          placeholder="Select Manager"
                        />
                        <ErrorMessage
                          name="manager"
                          component="div"
                          className="error"
                        />
                      </div>
                    </Box>
                    {(values.roleType === "PRE_SALES" ||
                      values.roleType === "BOTH") && (
                      <Box mb={2} width="100%" maxWidth="500px">
                        <Box marginBottom="0.5rem">
                          <label>
                            <strong>Property List</strong>
                          </label>
                        </Box>
                        <Box mb={2} width="100%" maxWidth="500px">
                          <div className="s-select">
                            <Select
                              id="project"
                              name="project"
                              options={propertyOptions}
                              value={propertyOptions.find(
                                (option) => option.value === values.project
                              )}
                              onChange={(selectedOption) =>
                                setFieldValue(
                                  "project",
                                  selectedOption ? selectedOption.value : ""
                                )
                              }
                              onBlur={handleBlur}
                              isClearable={true}
                              placeholder="Select Project"
                            />
                            <ErrorMessage
                              name="project"
                              component="div"
                              className="error"
                            />
                            <IoIosAddCircle
                              onClick={handleAddProject}
                              style={{
                                fontSize: "28px",
                                color: "green",
                                marginTop: "5px",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </Box>
                      </Box>
                    )}
                    {showAdditionalProjects &&
                      additionalProjects.map((_, index) => (
                        <Box key={index} mb={2} width="100%" maxWidth="500px">
                          <div className="s-select">
                            <Select
                              id={`additionalProjects[${index}]`}
                              name={`additionalProjects[${index}]`}
                              options={propertyOptions}
                              value={propertyOptions.find(
                                (option) =>
                                  option.value ===
                                  additionalProjects[index].project
                              )}
                              onChange={(selectedOption) =>
                                handleProjectChange(
                                  index,
                                  selectedOption,
                                  setFieldValue
                                )
                              }
                              onBlur={handleBlur}
                              isClearable={true}
                              placeholder="Select Additional Property"
                            />
                            <ErrorMessage
                              name={`additionalProjects[${index}]`}
                              component="div"
                              className="error"
                            />
                            <IoIosRemoveCircle
                              onClick={() => handleRemoveProject(index)}
                              style={{
                                fontSize: "28px",
                                color: "red",
                                cursor: "pointer",
                                marginTop: "5px",
                              }}
                            />
                          </div>
                        </Box>
                      ))}
                    <Box
                      mb={2}
                      width="100%"
                      display="flex"
                      justifyContent="center"
                    >
                      <ButtonComponent
                        type="submit"
                        name={"Update Member"}
                        disabled={isSubmitting}
                        height="43px"
                        fullWidth
                      />
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Paper>
        </Box>
      </Box>
    </MainLayout>
  );
};

export default EditExecutive;
