import React, { useEffect, useState } from "react";
import MainLayout from "../../../layout/MainLayout";
import { Paper, TextField, Grid, Typography, Box } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ButtonComponent from "../../../component/Button";
import "./AddProperty.css";
import { Link, useNavigate } from "react-router-dom";
import { getAPIHandler, postAPIHandler } from "../../../config/service";
import { propertySchema } from "../../../schemas";
import arrowBack from "../../../assets/arrowBack.svg";
import Select from "react-select";
import { State, City } from "country-state-city";
import success from "../../../assets/u86.png";
import axios from "axios";
import dayjs from "dayjs";
import { toast } from "react-hot-toast";
import BubbleLoader from "../../../component/Loader/BubbleLoader";

const AddProperty = () => {
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [customImage, setCustomImage] = useState(null);
  const [uploadedFileURLs, setUploadedFileURLs] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [vpOptions, setVpOptions] = useState([]);
  const [vpType, setVpType] = useState("");
  const [filteredVpOptions, setFilteredVpOptions] = useState([]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [images, setImages] = useState([
    "https://cdn.pixabay.com/photo/2016/11/29/03/53/house-1867187_1280.jpg",
  ]);
  const navigate = useNavigate();
  const countryOptions = [{ value: "IN", label: "India" }];

  const categoryOptions = [
    { value: "Residential", label: "Residential" },
    { value: "Commercial", label: "Commercial" },
    { value: "Villa", label: "Villa" },
  ];

  const formOptions = [
    { value: "1 BHK", label: "1 BHK" },
    { value: "2 BHK", label: "2 BHK" },
    { value: "2.5 BHK", label: "2.5 BHK" },
    { value: "3 BHK", label: "3 BHK" },
    { value: "3.5 BHK", label: "3.5 BHK" },
    { value: "4 BHK", label: "4 BHK" },
    { value: "4.5 BHK", label: "4.5 BHK" },
    { value: "5 BHK", label: "5 BHK" },
    { value: "PENTHOUSE", label: "PENTHOUSE" },
    { value: "DUPLEX", label: "DUPLEX" },
    { value: "PLOT", label: "PLOT" },
    { value: "FARMHOUSE", label: "FARMHOUSE" },
    { value: "STUDIO APARTMENTS", label: "STUDIO APARTMENTS" },
    { value: "SHOP", label: "SHOP" },
    { value: "FOOD COURT", label: "FOOD COURT" },
    { value: "OFFICE SPACE", label: "OFFICE SPACE" },
    { value: "INDUSTRIAL LAND", label: "INDUSTRIAL LAND" },
    { value: "SHED", label: "SHED" },
    { value: "SCO", label: "SCO" },
  ];

  const typeOptions = [
    { value: "Under Construction", label: "Under Construction" },
    { value: "Ready to Move", label: "Ready to Move" },
  ];

  const priceOptions = [
    { value: "Lakh", label: "Lakh" },
    { value: "Crore", label: "Crore" },
  ];

  useEffect(() => {
    fetchStateOptions();
    fetchUserOptions();
  }, []);

  useEffect(() => {
    const filterVpOptions = () => {
      if (vpType === "SALES") {
        setFilteredVpOptions(vpOptions.filter((vp) => vp.type === "SALES"));
      } else if (vpType === "PRE_SALES") {
        setFilteredVpOptions(vpOptions.filter((vp) => vp.type === "PRE_SALES"));
      } else {
        setFilteredVpOptions([]);
      }
    };

    filterVpOptions();
  }, [vpType, vpOptions]);

  const fetchStateOptions = () => {
    const states = State.getStatesOfCountry("IN").map((state) => ({
      value: state.isoCode,
      label: state.name,
    }));
    setStateOptions(states);
  };

  const fetchUserOptions = async () => {
    try {
      const vpResponse = await getAPIHandler({
        endPoint: "listUserDetailsByType",
        paramsData: { userType: "VP" },
      });

      console.log("API Response:", vpResponse.data.result.userDetails);

      const mapUsers = (users) =>
        users.map((user) => ({
          value: user._id,
          label: `${user.firstName} ${user.lastName}`,
          type: user.userRoleType ? user.userRoleType.toUpperCase() : "",
        }));

      const mappedUsers = mapUsers(vpResponse.data.result.userDetails);

      console.log("Mapped Users:", mappedUsers);

      setVpOptions(mappedUsers);
    } catch (error) {
      console.error("Error fetching user options:", error);
    }
  };

  const handleStateChange = (selectedState, setFieldValue) => {
    if (!selectedState) {
      setCityOptions([]);
      setFieldValue("city", "");
      return;
    }

    const cities = City.getCitiesOfState("IN", selectedState.value).map(
      (city) => ({
        value: city.name,
        label: city.name,
      })
    );
    setCityOptions(cities);
    setFieldValue("city", "");
  };

  const getLongLat = async (location) => {
    try {
      const response = await axios.get(
        `https://geocode.xyz/${encodeURIComponent(
          location
        )}?json=1&auth=37130815437857150226x10414`
      );

      const { latt: lat, longt: lon } = response.data;
      return { lat, lon };
    } catch (error) {
      console.error("Error fetching coordinates: ", error);
      return { lat: null, lon: null };
    }
  };

  const onSubmit = async (values, { setSubmitting }) => {
    setLoading(true);
    try {
      const { lat, lon } = await getLongLat(values.location);

      const filesURL = uploadedFileURLs.map((url) => ({
        fileType: "IMAGE",
        url: url,
      }));

      if (filesURL.length === 0) {
        toast.error("Please upload property images.");
        return;
      }

      const response = await postAPIHandler({
        endPoint: "addProperty",
        dataToSend: {
          propertyName: values.propertyName,
          RERANumber: values.areaNumber,
          unitType: values.areaFt,
          address: values.location,
          country: values.country,
          state: values.state,
          city: values.city,
          latitude: lat,
          longitude: lon,
          form: values.form,
          possession: values.listedDate,
          completedTime: values.possessionDate,
          category: values.category,
          about: values.description,
          constructionStatus: values.constructionStatus,
          memberType: values.vpType,
          minPrice: `${values.minPrice} ${values.PriceDropdownMin}`,
          maxPrice: `${values.maxPrice} ${values.PriceDropdownMax}`,
          memberId: values.vpTypeOptions,
          filesURL: filesURL,
        },
      });

      console.log(response.data);

      setShowSuccessPopup(true);

      setTimeout(() => {
        setShowSuccessPopup(false);
        navigate("/propertymanagement");
      }, 3000);
    } catch (error) {
      console.error("Error:", error);
      setSubmitting(false);
    }
    setLoading(false);
  };

  const handleFileSelect = async (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        setLoading(true);
        try {
          const reader = new FileReader();
          reader.onload = () => {
            setCustomImage(reader.result);
          };
          reader.readAsDataURL(file);

          const formData = new FormData();
          formData.append("file", file);

          const res = await postAPIHandler({
            endPoint: "uploadFile",
            dataToSend: formData,
          });

          console.log("File upload response:", res);

          setUploadedFileURLs((prevURLs) => [...prevURLs, res.data.result]);

          setImages((prevImages) => [...prevImages, res.data.result]);
        } catch (error) {
          console.error("Error uploading file:", error);
        }
        setLoading(false);
      } else {
        alert("Please select an image file.");
      }
    }
  };

  const handleThumbnailClick = (index) => {
    setSelectedImage(images[index]);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setCustomImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const renderAddPhotosButtonAndInput = () => (
    <Grid>
      <ButtonComponent
        height="43px"
        onClick={() => document.getElementById("imageInput").click()}
      >
        {loading ? (
          <BubbleLoader color="#FFF" size="0.7rem" />
        ) : (
          "Upload Images"
        )}{" "}
      </ButtonComponent>
      <input
        id="imageInput"
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleFileSelect}
      />
    </Grid>
  );

  return (
    <MainLayout>
      <Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            padding: "1rem",
          }}
        >
          <Typography style={{ fontWeight: 700, fontSize: "30px" }}>
            Property Management
          </Typography>
        </Grid>

        <Grid style={{ marginLeft: "15px", marginBottom: "15px" }}>
          <Paper
            elevation={0}
            style={{
              padding: "1rem",
              overflowY: "auto",
              scrollbarWidth: "none",
            }}
          >
            <div className="addproperty-header">
              <div className="arrow">
                <Link to={"/propertymanagement"}>
                  <img
                    src={arrowBack}
                    alt="arroWback"
                    style={{
                      width: "2.813rem",
                      height: "2.813rem",
                      cursor: "pointer",
                    }}
                  />
                </Link>
                <Typography
                  variant="h5"
                  sx={{ marginLeft: "1rem", fontWeight: 800 }}
                >
                  Add Property
                </Typography>
              </div>
            </div>

            <Box display="flex" justifyContent="center" margin="2rem">
              <Typography
                variant="h6"
                sx={{ marginLeft: "1rem", fontWeight: 700 }}
              >
                Property Details
              </Typography>
            </Box>

            <div className="add-property-container">
              <div className="add-property-content">
                <div
                  className="image-section"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                >
                  <div className="main-image-container">
                    {customImage || uploadedFileURLs.length > 0 ? (
                      <img
                        src={customImage || images[selectedImage]}
                        alt="Custom"
                        style={{ width: "100%", maxWidth: "600px" }}
                      />
                    ) : (
                      <div>
                        <div className="upload-overlay">
                          <span className="plus-icon">+</span>
                          <strong className="upload-message">
                            Drag and drop images to upload
                          </strong>
                        </div>
                        <img
                          src={images[selectedImage]}
                          alt="Property"
                          style={{ width: "100%", maxWidth: "600px" }}
                          onDoubleClick={() =>
                            document.getElementById("imageInput").click()
                          }
                        />
                      </div>
                    )}
                  </div>

                  {images.length > 1 && (
                    <div className="thumbnails">
                      {images.slice(1).map((image, index) => (
                        <img
                          key={index}
                          src={image}
                          alt="Thumbnail"
                          className={index === selectedImage ? "active" : ""}
                          onClick={() => handleThumbnailClick(index + 1)}
                        />
                      ))}
                    </div>
                  )}

                  {renderAddPhotosButtonAndInput()}
                </div>
              </div>

              <div className="add-property-content">
                <Formik
                  initialValues={{
                    propertyName: "",
                    areaNumber: "",
                    areaFt: "",
                    country: "",
                    state: "",
                    city: "",
                    category: "",
                    form: "",
                    constructionStatus: "",
                    listedDate: new Date(),
                    possessionDate: new Date(),
                    location: "",
                    minPrice: "",
                    maxPrice: "",
                    description: "",
                    vpType: "",
                    vpTypeOptions: "",
                  }}
                  validationSchema={propertySchema}
                  onSubmit={onSubmit}
                >
                  {({
                    values,
                    setFieldValue,
                    handleBlur,
                    handleChange,
                    touched,
                    errors,
                  }) => (
                    <Form>
                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              Property Name{" "}
                              <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Field
                              as={TextField}
                              type="text"
                              name="propertyName"
                              variant="outlined"
                              className="form-control"
                              placeholder="Enter property name"
                              onKeyPress={(e) => {
                                if (e.target.value.length === 0) {
                                  if (e.key === " ") e.preventDefault();
                                }
                                if (
                                  e.target.value.length >= 60 &&
                                  e.key !== "Backspace"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            <ErrorMessage
                              name="propertyName"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              RERA Number{" "}
                              <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Field
                              as={TextField}
                              type="text"
                              name="areaNumber"
                              variant="outlined"
                              className="form-control"
                              placeholder="Enter RERA number"
                              onKeyPress={(e) => {
                                if (e.target.value.length === 0) {
                                  if (e.key === " ") e.preventDefault();
                                }
                                if (
                                  e.target.value.length >= 60 &&
                                  e.key !== "Backspace"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            <ErrorMessage
                              name="areaNumber"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              Area in(sq ft.){" "}
                              <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Field
                              as={TextField}
                              type="text"
                              name="areaFt"
                              variant="outlined"
                              className="form-control"
                              placeholder="Enter area in(sq ft.)"
                              onKeyPress={(e) => {
                                if (e.target.value.length === 0) {
                                  if (e.key === " ") e.preventDefault();
                                }
                                if (
                                  e.target.value.length >= 60 &&
                                  e.key !== "Backspace"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            <ErrorMessage
                              name="areaFt"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              Country <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Select
                              className="select"
                              name="country"
                              options={countryOptions}
                              value={countryOptions.find(
                                (option) => option.label === values.country
                              )}
                              onChange={(selectedOption) =>
                                setFieldValue(
                                  "country",
                                  selectedOption ? selectedOption.label : ""
                                )
                              }
                              onBlur={handleBlur}
                              isClearable={true}
                              placeholder="Select country"
                              // isDisabled={true} // Makes the field non-editable
                            />
                            <ErrorMessage
                              name="country"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              State <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Select
                              className="select"
                              name="state"
                              options={stateOptions}
                              value={stateOptions.find(
                                (option) => option.label === values.state
                              )}
                              onChange={(selectedOption) => {
                                setFieldValue(
                                  "state",
                                  selectedOption ? selectedOption.label : ""
                                );
                                handleStateChange(
                                  selectedOption,
                                  setFieldValue
                                );
                              }}
                              onBlur={handleBlur}
                              isClearable={true}
                              placeholder="Select state"
                            />
                            <ErrorMessage
                              name="state"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              City <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Select
                              className="select"
                              name="city"
                              options={cityOptions}
                              value={cityOptions.find(
                                (option) => option.label === values.city
                              )}
                              onChange={(selectedOption) =>
                                setFieldValue(
                                  "city",
                                  selectedOption ? selectedOption.label : ""
                                )
                              }
                              onBlur={handleBlur}
                              isClearable={true}
                              placeholder="Select city"
                            />
                            <ErrorMessage
                              name="city"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              Category <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Select
                              className="select"
                              name="category"
                              options={categoryOptions}
                              value={categoryOptions.find(
                                (option) => option.value === values.category
                              )}
                              onChange={(selectedOption) =>
                                setFieldValue(
                                  "category",
                                  selectedOption ? selectedOption.value : ""
                                )
                              }
                              onBlur={handleBlur}
                              isClearable={true}
                              placeholder="Select Category"
                            />
                            <ErrorMessage
                              name="category"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              Form <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Select
                              className="select"
                              name="form"
                              options={formOptions}
                              value={formOptions.find(
                                (option) => option.value === values.form
                              )}
                              onChange={(selectedOption) =>
                                setFieldValue(
                                  "form",
                                  selectedOption ? selectedOption.value : ""
                                )
                              }
                              onBlur={handleBlur}
                              isClearable={true}
                              placeholder="Select Form"
                            />
                            <ErrorMessage
                              name="form"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              Construction Status
                              <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Select
                              className="select"
                              name="constructionStatus"
                              options={typeOptions}
                              value={typeOptions.find(
                                (option) =>
                                  option.value === values.constructionStatus
                              )}
                              onChange={(selectedOption) =>
                                setFieldValue(
                                  "constructionStatus",
                                  selectedOption ? selectedOption.value : ""
                                )
                              }
                              onBlur={handleBlur}
                              isClearable={true}
                              placeholder="Select construction status"
                            />
                            <ErrorMessage
                              name="constructionStatus"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              Possession <span style={{ color: "red" }}>*</span>
                              :
                            </strong>
                          </label>
                          <div className="errordiv">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  name="listedDate"
                                  sx={{
                                    width: "23rem",
                                    height: "2.4",
                                    "& .MuiInputBase-input": {
                                      fontSize: "15px",
                                      padding: "0.7rem",
                                    },
                                  }}
                                  value={
                                    values.listedDate
                                      ? dayjs(values.listedDate)
                                      : null
                                  }
                                  onChange={(newDate) =>
                                    setFieldValue("listedDate", newDate)
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      error={
                                        touched.listedDate &&
                                        Boolean(errors.listedDate)
                                      }
                                    />
                                  )}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                            {touched.listedDate && errors.listedDate ? (
                              <div className="error">{errors.listedDate}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              Compeleted Time
                              <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  name="possessionDate"
                                  disablePast
                                  sx={{
                                    width: "23rem", // Set desired width
                                    height: "2.4", // Set desired height
                                    "& .MuiInputBase-input": {
                                      // Target the input field
                                      fontSize: "15px", // Adjust font size if needed
                                      padding: "0.7rem",
                                    },
                                  }}
                                  value={
                                    values.possessionDate
                                      ? dayjs(values.possessionDate)
                                      : null
                                  }
                                  onChange={(newDate) =>
                                    setFieldValue("possessionDate", newDate)
                                  }
                                  onBlur={handleBlur}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      error={
                                        touched.possessionDate &&
                                        Boolean(errors.possessionDate)
                                      }
                                    />
                                  )}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                            {touched.possessionDate && errors.possessionDate ? (
                              <div className="error">
                                {errors.possessionDate}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              Location <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Field
                              as={TextField}
                              type="text"
                              name="location"
                              variant="outlined"
                              className="form-control"
                              placeholder="Enter location"
                              onKeyPress={(e) => {
                                if (e.target.value.length === 0) {
                                  if (e.key === " ") e.preventDefault();
                                }
                                if (
                                  e.target.value.length >= 60 &&
                                  e.key !== "Backspace"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(event) => {
                                handleChange(event);
                                getLongLat(event, setFieldValue);
                              }}
                            />
                            <ErrorMessage
                              name="location"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              Price Range<span style={{ color: "red" }}>*</span>{" "}
                              :{" "}
                            </strong>
                          </label>
                          <>
                            <div className="price-range">
                              <div className="price-content">
                                <span className="price-tag">Min:</span>
                                <div className="errordiv">
                                  <Field
                                    as={TextField}
                                    type="text"
                                    name="minPrice"
                                    variant="outlined"
                                    className="form-price"
                                    placeholder="value"
                                    onKeyPress={(e) => {
                                      if (e.target.value.length === 0) {
                                        if (e.key === " ") e.preventDefault();
                                      }
                                      if (
                                        e.target.value.length >= 60 &&
                                        e.key !== "Backspace"
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                  <ErrorMessage
                                    name="minPrice"
                                    component="div"
                                    className="error"
                                  />
                                </div>
                                <Select
                                  className="price-dropdown"
                                  name="PriceDropdownMin"
                                  options={priceOptions}
                                  value={priceOptions.find(
                                    (option) =>
                                      option.value === values.PriceDropdownMin
                                  )}
                                  onChange={(selectedOption) =>
                                    setFieldValue(
                                      "PriceDropdownMin",
                                      selectedOption ? selectedOption.value : ""
                                    )
                                  }
                                  onBlur={handleBlur}
                                  isClearable={true}
                                  placeholder="Unit"
                                />
                              </div>

                              <div className="price-content">
                                <span className="price-tag">Max:</span>

                                <div className="errordiv">
                                  <Field
                                    as={TextField}
                                    type="text"
                                    name="maxPrice"
                                    variant="outlined"
                                    className="form-price"
                                    placeholder="value"
                                    onKeyPress={(e) => {
                                      if (e.target.value.length === 0) {
                                        if (e.key === " ") e.preventDefault();
                                      }
                                      if (
                                        e.target.value.length >= 60 &&
                                        e.key !== "Backspace"
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                  <ErrorMessage
                                    name="maxPrice"
                                    component="div"
                                    className="error"
                                  />
                                </div>

                                <Select
                                  className="price-dropdown"
                                  name="PriceDropdownMax"
                                  options={priceOptions}
                                  value={priceOptions.find(
                                    (option) =>
                                      option.value === values.PriceDropdownMax
                                  )}
                                  onChange={(selectedOption) =>
                                    setFieldValue(
                                      "PriceDropdownMax",
                                      selectedOption ? selectedOption.value : ""
                                    )
                                  }
                                  onBlur={handleBlur}
                                  isClearable={true}
                                  placeholder="Unit"
                                />
                              </div>
                            </div>
                          </>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="developer-title">
                          <label htmlFor="description">
                            <strong>About:</strong>
                          </label>
                          <div className="errordiv">
                            <Field
                              as={TextField}
                              multiline
                              rows={4}
                              name="description"
                              variant="outlined"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="description"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="developer-title">
                          <label htmlFor="vpType">
                            <strong>
                              Assign VP For This Project
                              <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <div className="radio-buttons">
                              <label>
                                <Field
                                  type="radio"
                                  name="vpType"
                                  value="SALES"
                                  onClick={() => setVpType("SALES")}
                                />
                                Sales
                              </label>
                              <label>
                                <Field
                                  type="radio"
                                  name="vpType"
                                  value="PRE_SALES"
                                  onClick={() => setVpType("PRE_SALES")}
                                />
                                Presales
                              </label>
                            </div>
                            <ErrorMessage
                              name="vpType"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>

                      {values.vpType && (
                        <div className="form-group">
                          <div className="developer-title">
                            <label htmlFor="vpTypeOptions">
                              <strong>Select Member:</strong>
                            </label>

                            <div className="errordiv">
                              <Select
                                className="select"
                                name="vpTypeOptions"
                                options={filteredVpOptions}
                                value={filteredVpOptions.find(
                                  (option) =>
                                    option.value === values.vpTypeOptions
                                )}
                                onChange={(selectedOption) =>
                                  setFieldValue(
                                    "vpTypeOptions",
                                    selectedOption ? selectedOption.value : ""
                                  )
                                }
                                onBlur={handleBlur}
                                isClearable={true}
                                placeholder="Select Member"
                              />

                              <ErrorMessage
                                name="vpTypeOptions"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="button-container">
                        <ButtonComponent type="submit" height="43px">
                          {" "}
                          {loading ? (
                            <BubbleLoader color="#FFF" size="0.7rem" />
                          ) : (
                            "Add Property"
                          )}{" "}
                        </ButtonComponent>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
      {showSuccessPopup && (
        <div className="success-popup">
          <div className="popup-content">
            <img className="success-icon" src={success}></img>
            <p>Property added successfully!</p>
          </div>
        </div>
      )}
    </MainLayout>
  );
};

export default AddProperty;
