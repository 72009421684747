import { Paper, Grid, Typography } from "@mui/material";
import React from "react";
import ViewDetail from "../../../component/viewDetail/ViewDetail";
import arrowBack from "../../../assets/arrowBack.svg"
import MainLayout from "../../../layout/MainLayout";
import "./ViewDeveloper.css";
import { Link, useParams } from "react-router-dom";
import { getAPIHandler } from "../../../config/service";

const ViewDeveloper = () => {
  const { id } = useParams();
  const [data, setData] = React.useState({});
 

  const getData = async () => {
    try {
     

      const response = await getAPIHandler({
        endPoint:"getDeveloperById",
        paramsData: {
          developerId: id
        },

      })

      console.log("Response:", response.data);
      setData(response.data.result);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  const developerDetails = [
    { left: "Developer ID", value: data ? data._id : null },
    { left: "Full Name", value: data ? data.fullName : null },
    {
      left: "Created At",
      value: data ? new Date(data.createdAt).toLocaleString("en-IN", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Asia/Kolkata",
      }) : null
    },
    {
      left: "Status",
      value: data ? (
        <span style={{ color: data.status === "ACTIVE" ? "green" : "red" }}>
          {data.status}
        </span>
      ) : null
    }
  ];
  
  return (
    <MainLayout>
      <Grid>
        <Grid
          style={{
            display: "flex",

            alignItems: "center",
            padding: "1rem",
          }}
        >
          <Typography style={{ fontWeight: 700, fontSize: "30px" }}>
            Developer Management
          </Typography>
        </Grid>
        <Grid style={{ marginLeft: "15px", marginBottom: "15px" }}>
          <Paper elevation={0}>
            <div className="icon">
              <Link to={"/developermanagement"}>
              <img
              src={arrowBack}
              alt="arroWback"
              style={{
                width: "2.813rem",
                height: "2.813rem",
                cursor: "pointer",
              }}
            />
              </Link>
            </div>
            <div className="ViewDeveloper-container">
              <h2>Developer Detail</h2>
              <ViewDetail type="developer" details={developerDetails} />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default ViewDeveloper;
