import React, { useState } from "react";
import { Grid, styled, Box } from "@mui/material";
import ButtonComponent from "../Button";
import BasicDatePicker from "../DatePicker";
import SearchBar from "../SearchBar";
// import Dropdown from "../dropdown/Dropdown";

const Filter = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);



  const StyledBox = styled(Box)(({ theme }) => ({
    padding: "10px",
    borderRadius: "15px",
    backgroundColor: "rgba(0, 176, 80, 0.05)",
    "& .displayEnd": {
      display: "flex",
      justifyContent: "flex-end",
      backgroundColor: "rgba(0, 0, 0, 0.02)",
      paddingTop: "10px",
      columnGap: "8px",
      [theme.breakpoints.down("md")]: {
        columnGap: "0px",
      },
    },
    "& .MuiInputBase-root": {
      color: "#0B1426 !important",
      height: "44px",
      backgroundColor: "transparent",
    },
    "& .MuiOutlinedInput-input": {
      color: "#0B1426",
    },
  }));
  

  return (
    <StyledBox>
    <Grid container spacing={3} alignItems="flex-end">
    <Grid item xs={12} sm={6} md={4}>
          <SearchBar
            placeholder={"search by property name"}
            value={searchQuery}
            options={[]}
          />
        </Grid>

      <Grid item xs={12} sm={6} md={2}>
        <BasicDatePicker
          label="From Date"
    
          value={fromDate}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={2}>
        <BasicDatePicker
          label="To Date"
       
          value={toDate}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={2}>
        <ButtonComponent
          name="Reset Filter"
          borderRadius="100px"
          backgroundColor="rgba(0, 0, 0, 0.05)"
          color="black"
          width={"100%"}
          margin={"0px"}
          height={"45px"}
        />
      </Grid>
    </Grid>
  </StyledBox>
  );
};

export default Filter;



// <Grid container>
//       <Grid
//         style={{
//           display: "flex",
//           alignItems: "center",
//           flexDirection: "column",
//           backgroundColor: "rgba(0, 0, 0, 0.02)",
//           borderRadius: "15px",
//           width: "100%",
//         }}
//       >
//         <Grid
//           container
//           style={{
//             display: "flex",
//             justifyContent: "space-evenly",
//             marginTop:"1rem"
        
    
//           }}
//         >
//           <Grid item > 
//             <Dropdown
//               options={["Select Project"]}
//               onSelect={handleSelect}
//             />
//           </Grid>
//           <Grid item > 
//             <Dropdown
//               options={["Select Source"]}
//               onSelect={handleSelect}
//             />
//           </Grid>
//           <Grid item > 
//             <Dropdown
//               options={["Select Vice President"]}
//               onSelect={handleSelect}
//             />
//           </Grid>
//           <Grid item > 
//             <Dropdown
//               options={["Select Manager"]}
//               onSelect={handleSelect}
//             />
//           </Grid>
//           <Grid item> 
//             <Dropdown
//               options={["Select Executive"]}
//               onSelect={handleSelect}
//             />
//           </Grid>
//         </Grid>

//         <Grid
//           container
//           style={{
//             display: "flex",
            
//             justifyContent: "space-evenly",
//             alignItems: "center",
//             width: "100%", 
//           }}
//         >
//           <Grid
//             item
//             style={{
//               display: "flex", 
 

//               gap:"0.5rem"
//             }}
//           >
//             <BasicDatePicker />
//             <BasicDatePicker />
//           </Grid>
//           <Grid item>
//             <ButtonComponent
//               name={"Clear"}
//               borderRadius={"100px"}
//               backgroundColor={"rgba(0, 0, 0, 0.05)"}
//               color={"black"}
//             />
//           </Grid>
//         </Grid>
//       </Grid>
//     </Grid>
